import React from 'react'
import { ReactComponent as Chevronleft } from '../assets/images/ChevronLeft.svg'
const PageTitleBar = ({title, onPrevPage, ItemId} ) => {
  return (
   
        <div className='flex bg-white page-header my-3 py-3 items-center gap-1' >
        {onPrevPage &&
    
         
          <Chevronleft className='cursor-pointer
          ' onClick={onPrevPage} disabled={ItemId===null}/>
    
        }
        <div className=' text-blue-900 text-base font-medium'>{title}</div>
        </div>
   
  )
}

export default PageTitleBar