import React from 'react'
import { NumericFormat } from 'react-number-format'
import {format} from 'date-fns'
import formatText from './formatText'
const Invoice = ({receipt}) => {
    const Colour =  receipt.vendor.vendor_settings.invoice_color||'#3513f1';
    const name = localStorage.getItem("name");
    const { completed, approved, signatures } = receipt.workflow;
    const { phone, email, vendor_name } = receipt.vendor;

    // Determine the status, comment, and color
    let status = '';
    let comment = '';
    let statusColor = '';
  
    if (completed && approved) {
      status = 'Approved';
      // comment = signatures.find(sig => sig.role === 'line_manager')?.comment || '';
      statusColor = 'text-green-600'; // Green for Approved
    } else if (completed && !approved) {
      status = 'Declined';
      comment = signatures.find(sig => sig.role === 'internal_control')?.comment || '';
      statusColor = 'text-red-600'; // Red for Declined
    } else {
      status = 'Pending';
      statusColor = 'text-yellow-600'; // Yellow for Pending
    }
  return (
    <div className='w-full bg-white overflow-auto'>
    <div className="flex flex-col md:flex-row  md:justify-between px-4">
      <div>
        <img
          // ref={imageRef}
          src={receipt?.vendor?.vendor_settings?.logo}
          alt="vendor logo"
          className="w-36 h-36 my-3 object-contain"
        />
        <p
          className="text-xl py-1 font-bold"
          style={{ color: Colour }}
        >
          {vendor_name || ''}
        
        </p>
      <p className="text-base">Telephone: {phone ||''} </p>
        <p className="text-base">Email: {email || ''} </p>
        <div
          className="w-full text-white text-base font-semibold bg-teal-600 pl-2 py-2 mt-3"
          style={{ backgroundColor: Colour }}
        >
          BILL TO
        </div>
        <div className="text-[#545454] py-1">FOOD CONCEPT LTD</div>
        <div className="text-[#545454] py-1">
         {receipt.grn.store.metadata.store_name}
        </div>
        <div className="text-[#545454] py-1">{receipt.grn.store.icg_warehouse_name
        }</div>
      </div>
      <div>
        <div
          className="text-right font-bold text-xl mb-1"
          style={{ color: Colour }}
        >
          INVOICE
        </div>
        <div
          className="border-2 font-normal text-[#545454] flex flex-col"
          style={{ borderColor: Colour }}
        >
          <div className="flex p-2 m-0">
            <span>Due Date</span>
            <span className="text-right flex-grow ">
              {format(new Date(receipt?.due_date), 'dd-MMM-yyyy')}
            </span>
          </div>
          <div className="flex gap-36 m-0 p-2">
                      <span>Reference Number</span>
                      <span className="flex-grow text-right">
                        {receipt?.external_document_id}
                      </span>
                    </div>
          <div className="flex gap-36 m-0 p-2">
            <span>Purchase Order Number</span>
            <span className="flex-grow text-right">
              {receipt?.grn?.purchase_order?.po_id}
            </span>
          </div>
          <div className="flex gap-36 m-0 p-2">
            <span>GRN</span>
            <span className="flex-grow text-right">
              {/* ₦
              <NumericFormat
                value={receipt.gross_total}
                displayType="text"
                thousandSeparator={true}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale
              /> */}
              {receipt.grn.grn_number}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="overflow-auto flex flex-col max-h-screen m-4 ">
    <table className=" w-[1024px]">
      <thead
        className=" text-white text-sm w-full"
        style={{ backgroundColor: Colour }}
      >
        <tr>
          <td className="text-sm font-medium py-2 px-4">SN</td>
          <td className="text-sm font-medium  px-4">Description</td>
          <td className="text-sm font-medium  px-4">UoM</td>
          <td className="text-sm font-medium text-right py-2 px-4">
            Unit Price
          </td>
          <td className="text-sm font-medium text-right pr-4 px-4">
            Quantity
          </td>
           <td className="text-sm font-medium text-right pr-4 px-4">
            Tax
          </td>
          <td className="text-sm font-medium pl-4 px-4">Discount</td>
          <td className="text-sm font-medium text-right pr-4 px-4">
            Total Amount
          </td>
      
            
       
        </tr>
      </thead>
      <tbody>
        {receipt.invoice_line_items.map((item, index) => (
          <tr key={index} className="border-b dark:border-gray-700 text-sm">
            <td className="text-sm py-3 px-4">{index + 1}</td>
            <td className="text-sm px-4">{item?.grn_line_item?.purchase_order_line_item?.product_name}</td>
            <td className=" text-sm px-4">{item?.grn_line_item?.purchase_order_line_item?.metadata?.ListUnitPriceBaseUnitCode }</td>
            <td className="text-sm px-4 text-right pr-4">
              ₦<NumericFormat value={item?.grn_line_item?.purchase_order_line_item?.unit_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
            <td className="pl-4 text-sm px-4 text-right pr-4">
              <NumericFormat value={item.quantity ? item.quantity : item.quantity_received} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
            
              <td className="text-sm px-4 text-right pr-4 pl-8">
              ₦<NumericFormat value={item?.tax_amount} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
              </td> 
            <td className="text-sm px-4 pl-4">{item.discount ? item.discount : 0.00}</td>
            <td className="text-sm px-4 text-right pr-4">
              ₦<NumericFormat value={item?.net_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
</div>
  
<div className="flex w-full px-4 items-center">
      <div className="w-full mt-8 text-[#545454]">
       
      </div>

      <div className="w-full mt-8 text-[#545454]">
        <div className="text-right" style={{ color: Colour }}>
          Subtotal: ₦
          <NumericFormat value={receipt.net_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
        </div>
        <div className="text-right mt-2" style={{ color: Colour }}>
          Tax: ₦
          <NumericFormat value={receipt.total_tax_amount} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
        </div>
        <div className="text-right mt-2 font-bold text-lg" style={{ color: Colour }}>
          Total: ₦
          <NumericFormat value={receipt.gross_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
        </div>
        {/* Status section */}
        {/* <div className={`text-right mt-2 font-bold text-lg ${statusColor}`}>
          Status: {status}
        </div> */}
        {/* Comment section */}
        {/* {comment && (
          <div className="text-right mt-2" style={{ color: Colour }}>
            <strong>Comment:</strong> {comment}
          </div>
        )} */}
      </div>
    </div>
    <div className="py-8">
    <div className="flex justify-center items-center px-10 lg:px-40 ">
  <div>
    <p className="text-center text-lg signature"></p>
    <p className="text-center text-lg signature">Vendor's signature</p>
    <hr className="divider" />
    <p className="text-center text-sm my-2">Vendor Signature</p>
  </div>
 
</div>

{/* <div className="flex flex-col px-10 lg:px-40 my-4 space-y-6">
  {receipt.workflow.signatories.map((signatur, index) => {
    const signed = receipt.workflow.signatures.find(
      (signature) => signature.role === signatur
    );

    return (
      <div key={index} className="flex justify-between items-end ">
        <div>
          <p className="text-center text-sm">
            {signed ? signed.signer.name : ''}
          </p>
          <hr className="divider" />
          <p className="text-center text-sm my-2">
            {formatText(signatur)}
          </p>
        </div>
        <div>
          <p className="text-center text-sm">
            {signed
              ? format(new Date(signed.date_signed), 'dd-MM-yyyy')
              : ''}
          </p>
          <hr className="divider" />
          <p className="text-center text-sm my-2">Date</p>
        </div>
      </div>
    );
  })}
</div> */}

    </div>
   



   
  </div>
  )
}

export default Invoice