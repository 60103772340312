import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import { NumericFormat } from 'react-number-format';
import { ReactComponent as Expand } from '../assets/images/arrowdowmnfilled.svg';
import TablePagination from '@mui/material/TablePagination';
import { ReactComponent as CollapseIcon } from '../assets/images/chevronUP.svg';
import { ReactComponent as ChevronRight } from '../assets/images/mdi_chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../assets/images/mdi_chevron-left.svg';
import { ReactComponent as ArrowLeft } from '../assets/images/arrowLeft.svg';
import { FormControlLabel } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function Row({datas, selectedRows, handleRowSelect,rowsPerPage,page,firstIndex}) {
  
  const [openRows, setOpenRows] = useState({});

    const toggleRow = (index) => {
      setOpenRows((prevOpenRows) => ({
        ...prevOpenRows,
        [index]: !prevOpenRows[index]
      }));
    };

  console.log(datas,'ejjj')
 
const handleCollapseItemSelect = (index,subIndex) => {
  const subItemKey = `${index}-${subIndex}`; // Use x instead of index
  const selectedIndex = selectedRows.indexOf(subItemKey);
  let newSelected = [...selectedRows];

  if (selectedIndex === -1) {
    // If the sub-item is not already selected, add it to the selected rows
    newSelected.push(subItemKey);
  } else {
    // If the sub-item is already selected, remove it from the selected rows
    newSelected.splice(selectedIndex, 1);
  }

  // Update selected rows state
  handleRowSelect(newSelected);
};

const handleMainRowSelect = (index) => {
  const selectedIndex = selectedRows.indexOf(index);
  const newSelected = [...selectedRows];

  if (selectedIndex === -1) {
    // Main row is not selected, proceed with selection
    newSelected.push(index);
    console.log(datas, 'datas');

    datas.results[index]?.grn_line_items?.forEach((lineItem, i) => {
      if (!lineItem.is_invoiced) {  // Skip lineItems that are invoiced
        const lineItemIndex = `${index}-${i}`;

        // Check if lineItemIndex is already selected
        if (!newSelected.includes(lineItemIndex)) {
          console.log(lineItemIndex, 'index 1');
          newSelected.push(lineItemIndex);
        }

        lineItem?.grn_line_items?.forEach((grnItem, j) => {
          const grnItemIndex = `${lineItemIndex}-${j}`;

          // Check if grnItemIndex is already selected
          if (!newSelected.includes(grnItemIndex)) {
            console.log(grnItemIndex, 'index 2');
            newSelected.push(grnItemIndex);
          }
        });
      }
    });
  } else {
    // Main row is already selected, proceed with deselection
    newSelected.splice(selectedIndex, 1);
    
    datas.results[index]?.grn_line_items?.forEach((lineItem, i) => {
      const lineItemIndex = `${index}-${i}`;
      const subIndex = newSelected.indexOf(lineItemIndex);

      if (subIndex !== -1) {
        newSelected.splice(subIndex, 1);
      }

      lineItem?.grn_line_items?.forEach((grnItem, j) => {
        const grnItemIndex = `${lineItemIndex}-${j}`;
        const subSubIndex = newSelected.indexOf(grnItemIndex);

        if (subSubIndex !== -1) {
          newSelected.splice(subSubIndex, 1);
        }
      });
    });
  }

  handleRowSelect(newSelected);
};


function formatDate(inputDate) {
  const parts = inputDate.split('-');
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  
  return `${day}-${month}-${year}`;
}

const handleMainCheckboxChange = (event, index) => {
  handleMainRowSelect(index);
};
console.log(datas,'dssssss')
  return (
    <>
 {(rowsPerPage > 0
            ? datas.results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : datas
          ).map((item,x) => (
  <React.Fragment key={x}>
    <TableRow sx={{ '&:hover': { backgroundColor: '#fafafa', border: 'none' } }} key={x}>
      <TableCell className='text-white font-semibold w-4 text-sm'>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => toggleRow(x)}
        >
          {openRows[x] ? <CollapseIcon /> : <Expand />}
        </IconButton>
      </TableCell>
      <TableCell className='flex py-1 pl-8'>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedRows.includes(x+firstIndex)}
              onChange={(event) => handleMainCheckboxChange(event, x+firstIndex)}
              disabled={item.invoice_status_text === 'Finished'}
      style={{ cursor: item.invoice_status_text === 'Finished' ? ' not-allowed' : 'pointer' }}
            />
          }
          label={x+1+firstIndex}
        />
      </TableCell>
      <TableCell className='text-sm'>{item.grn_number}</TableCell>
      <TableCell className='text-sm'>{formatDate(item.created)}</TableCell>
      <TableCell>
        <NumericFormat
          value={item.total_value_received}
          displayType={'text'}
          thousandSeparator={true}
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
        />
      </TableCell>  
    
     <TableCell>
        <span className={`rounded-3xl px-3 py-1 border ${item.invoice_status_text==='Finished'
          ? 'bg-green-200 text-green-700 border-green-700' : 'bg-gray-100 text-gray-600 border-gray-600'}`}>
          {item.invoice_status_text==='Finished' ? 'Invoiced' : 'Not Invoiced'}
        </span>
      </TableCell>
      <TableCell className='text-sm'>{item.invoiced_quantity?item.invoiced_quantity
:0}</TableCell>
     
    </TableRow>
    <TableRow >
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={12}
      >
        <Collapse in={openRows[x]} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <table size="small" aria-label="items shadow" className='w-full shadow-sm'>
              <thead className='bg-gray-50 text-white'>
                <tr sx={{border:0}}>
                  <td className='text-gray-700 font-medium text-sm py-2 px-2'>Product Name </td>
                  <td className='text-gray-700 font-medium text-sm py-3'>Product Code</td>
                  <td className='text-gray-700 font-medium text-sm py-2 text-right pr-8'>Unit Price </td>
                  <td className='text-gray-700 font-medium text-sm py-2 text-right pr-8'>Quantity Delivered</td>
                  {/* <td className='text-gray-700 font-medium text-sm py-2'>Outstanding Quantity</td> */}
                  <td className='text-gray-700 font-medium text-sm py-2 text-right pr-16'>Tax</td>
                  <td className='text-gray-700 font-medium text-sm py-2 text-right pr-4'>Discount</td>
                </tr>
              </thead>
              <TableBody>
                {item.grn_line_items.map((subItem, subIndex) => (
                  <tr
                    key={subItem.ObjectID}
                    className='bg-white border-t dark:border-gray-700 text-sm'
                    sx={{ '&:hover': { backgroundColor: '#f0f0f0' },border:0 }}
                  >
                {/* <td>
                <Checkbox
  checked={selectedRows.includes(`${x+firstIndex}-${subIndex}`)}
  disabled={subItem.is_invoiced}
  onChange={() => handleCollapseItemSelect(x+firstIndex,subIndex)} // Pass subIndex here
  color="primary"
/>
                </td> */}


                    <td className=" py-2 text-sm px-2">{subItem.purchase_order_line_item.product_name}</td>
                    <td className=" py-2 text-sm">
                    {subItem.purchase_order_line_item.metadata.ProductID}
                    </td>
                    {/* <td className="px-4 py-2 text-sm">{subItem.date_received}</td> */}
                    <td className="text-right pr-8 py-2 text-sm">
                      <NumericFormat
                        value={subItem.purchase_order_line_item.unit_price}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </td>
                    <td className="text-right pr-8 py-2 text-sm">
                      <NumericFormat
                        value={subItem.quantity_received}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </td>
                    {/* <td className='text-sm'>
                    <NumericFormat
                        value={subItem.value_received}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </td> */}
            
      <td className='text-right pr-16'>
      <NumericFormat
                        value= {subItem.tax_value}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                      />
      </td>
      <td className='text-right pr-4'>
        {subItem.purchase_order_line_item.metadata.DiscountAmount?subItem.purchase_order_line_item.metadata.DiscountAmount:0}
      </td>
                  </tr>
                ))}
              </TableBody>
            </table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
))}

    </>
  );
}

Row.propTypes = {
  datas: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired,
  handleRowSelect: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

function CollapsibleTable({ handlePrevious, handleNext, selectedPO,vendorInfo, selectedGRN, currentPage, totalPages }) {

  const [selectedRows, setSelectedRows] = useState([]);
console.log(selectedGRN,'.....')
console.log(selectedRows,'ejjj')
const [data,setDatas]=useState( selectedGRN)

  const handleRowSelect = (newSelected) => {
    setSelectedRows(newSelected);
  };
  const formatDate = (date) => {
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};
  
  const createPostData = () => {
    const navigationData = {
      poNumber: selectedPO, // Assuming selectedPO holds the PO number
      dateCreated: formatDate(new Date()), // Format the current date
      filteredData: filtered, // Assuming filtered contains the filtered selectedGRN.results.data
    };
    console.log(navigationData)
    return navigationData
  };

  // Function to handle the click event for selecting all checkboxes
  const handleSelectAllClick = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const newSelectedRows = [];
  
      selectedGRN.results.forEach((item, index) => {
        if (item.invoice_status_text !== 'Finished') {
          let shouldAddMainIndex = true;
  
          item.grn_line_items.forEach((subItem, subIndex) => {
            if (!subItem.is_invoiced) {
              newSelectedRows.push(`${index}-${subIndex}`);
            } else {
              shouldAddMainIndex = false;
            }
          });
  
          if (shouldAddMainIndex) {
            newSelectedRows.push(index);
          }
        }
      });
  
      setSelectedRows(newSelectedRows);
    } else {
      // Clear selectedRows if the checkbox is unchecked
      setSelectedRows([]);
    }
  };
  
  

  useEffect(() => {
    const restoreSelectedRows = () => {
      const savedSelectedRows = localStorage.getItem('selectedRows');
      if (savedSelectedRows) {
        setSelectedRows(JSON.parse(savedSelectedRows));
      }
    };

    restoreSelectedRows();
  }, []);

  // Save selected rows to local storage when selectedRows changes
  useEffect(() => {
    localStorage.setItem('selectedRows', JSON.stringify(selectedRows));
  }, [selectedRows]);

  // Clear selected rows from local storage on page reload
  useEffect(() => {
    const clearSelectedRowsOnReload = () => {
      localStorage.removeItem('selectedRows');
      localStorage.removeItem('selectedItems');
      localStorage.removeItem('ReferenceNumber');
    };

    window.addEventListener('beforeunload', clearSelectedRowsOnReload);

    return () => {
      window.removeEventListener('beforeunload', clearSelectedRowsOnReload);
    };
  }, []);


  const handleNavigate = () => {
    handleNext(); // Assuming handleNext is defined somewhere
    const postData = createPostData();
    localStorage.setItem('SelectedItems', JSON.stringify(postData));
  };

  console.log(data)
  console.log(selectedGRN,'selectedgrn')
  const filtered = selectedRows.flatMap((index) => {
    const [grnIndex, subIndex] = index.toString().split("-").map(Number);
    const grnItem = selectedGRN.results[grnIndex];
    return grnItem ? grnItem.grn_line_items.filter((_, i) => i === subIndex) : [];
  });
  console.log(filtered,';filtr')
  
  const handleChekmark = (index,rowIndex) => {
    setDatas(prevDatas => {
      console.log(prevDatas,'prev')
      const updatedDatas = [...prevDatas];
      
      console.log(index,'updatdd')
      updatedDatas[index].grn_line_items[rowIndex].taxStatus = !updatedDatas[index].grn_line_items[rowIndex].taxStatus;
     
      
      return updatedDatas;
    });
    console.log(data)
  };
  
  const handleSwitchChange = (index,rowIndex) => {
    setDatas(prevDatas => {
      console.log(prevDatas,'ticked')
      const updatedDatas = [...prevDatas];
      updatedDatas[index].grn_line_items[rowIndex].discountStatus = !updatedDatas[index].grn_line_items[rowIndex].discountStatus;
      return updatedDatas;
    });
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const firstIndex= page * rowsPerPage;
 // console.log(firstIndex,'firstIndex')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <div className="  pb-5 box-shadow bg-white ">
        <div className="flex p-3 justify-between bg-blue-800 text-base text-white">
          <div className="flex gap-2">
            <button className='border-0 bg-transparent' onClick={handlePrevious}><ArrowLeft/></button>
            <div className="flex">Invoice Details</div>
          </div>
          <span>Step {currentPage} of {totalPages}</span>
        </div>
        <div className="flex md:gap-36 gap-4 mt-3 px-4 flex-col md:flex-row">
          <div className="w-full flex md:items-center  flex-col md:flex-row">
            <label htmlFor="vendor" className='mb-0 w-28  text-sm'>Vendor:</label>
            <input
              type="text"
              id="vendor"
              value={vendorInfo}
              className="mt-1 block w-full p-2  username-input shadow-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none"
            />
          </div>
          <div className="w-full flex md:items-center flex-col md:flex-row">
            <label htmlFor="vendor" className='mb-0  w-28 text-sm'>PO Number:</label>
            <input
              type="text"
              id="vendor"
              value={selectedPO}
              className="mt-1 block  p-2 w-full username-input shadow-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none"
            />
          </div>
        </div>
      </div>
      <TableContainer className='box-shadow mt-4 w-full overflow-x-auto' component={Paper}>
        <div className="flex flex-col">
          <Table aria-label="collapsible table" >
            <TableHead className='bg-blue-800 text-white'>
              <TableRow>
                <TableCell className='text-white font-semibold text-sm'></TableCell>
                <TableCell>
                <FormControlLabel
  control={
    <input
      onChange={handleSelectAllClick}
      checked={
        selectedRows.length ===
        selectedGRN.results.reduce((acc, item, index) => {
          let count = 0;
          if (item.invoice_status_text !== 'Finished') {
            count += 1; // Count main item
            count += item.grn_line_items.filter(subItem => !subItem.is_invoiced).length; // Count selectable sub-items
          }
          return acc + count;
        }, 0)
      }
      type='checkbox'
      className="form-checkbox h-5 w-5 text-blue-500 border-white mr-1 bg-white focus:ring-0"
    />
  }
  label='SN'
  className='text-white font-semibold text-sm'
/>

                </TableCell>
                {/* <TableCell className='text-white font-semibold text-sm'>Total Quantity</TableCell> */}
				<TableCell className='font-semibold text-sm px-4 py-2 text-white'>GRN Number</TableCell>
                  <TableCell className='font-semibold text-sm py-2 text-white'>Date Delivered</TableCell>
                  <TableCell className='font-semibold text-sm py-2 text-white'>Net Amount</TableCell>
                  <TableCell className='font-semibold text-sm py-2 text-white'>Invoice Status</TableCell>
                  <TableCell className='font-semibold text-sm py-2 text-white text-right pr-8'>Quantity Invoiced</TableCell>
                  {/* <TableCell className='font-semibold text-sm py-2 text-white'>Outstanding Quantity</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <Row
                datas={selectedGRN}
                selectedRows={selectedRows}
                handleRowSelect={handleRowSelect}
                handleChekmark={handleChekmark}
                handleSwitchChange={handleSwitchChange}
                rowsPerPage={rowsPerPage}
                page={page}
                firstIndex={firstIndex}
              />
            </TableBody>
          </Table>
          {selectedRows.length > 0 &&
            <button className='bg-blue-600 text-white self-end px-5 py-2 mt-4 mx-4  font-semibold text-base rounded-sm hover:bg-blue-700' onClick={handleNavigate}>Continue</button>
          }
        </div>
        <TablePagination
              rowsPerPageOptions={[ 5, 10,20
              ]}
              colSpan={3}
              count={selectedGRN.results.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationAction}
            />
      </TableContainer>
    </>
  );
}

CollapsibleTable.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  selectedPO: PropTypes.string.isRequired,
  selectedGRN: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  inputValue: PropTypes.string.isRequired,
  handleRowSelect: PropTypes.func.isRequired,
};

export default CollapsibleTable;

function TablePaginationAction(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationAction.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
