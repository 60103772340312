import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { DateRangePicker } from 'rsuite';
import Pagination from './Pagination'
import { ReactComponent as Arrowright } from '../assets/images/ArrowRight.svg';
import { ReactComponent as DownloadIcon } from '../assets/images/downloadIcon.svg';
import Modal from './Modal';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import formatText from './formatText';


const TableComponent = ({
  data,
  recent_invoice,
 total,
 size,
  onButtonClick,
  count,
  page,
  complete,
  allInvoices
}) => {
  const location=useLocation()
  const [expand, setExpand] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleStatus = (item) => {
    setIsListVisible(!isListVisible)
    setSelectedInvoice(item);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedInvoice(null);
  };
  const handleToggleList = (item) => {
    setSelectedItem(item);
    setIsListVisible(!isListVisible);
  };
  const filterData = () => {
    let filtered = data;

    if (searchValue) {
        const lowercasedSearchValue = searchValue.toLowerCase();
        filtered = filtered.filter(item => {
            const poId = item.grn.purchase_order.po_id;
             const GRN = item.grn.grn_number
             ;
            const externalId = item.external_document_id;
            const amount = item.net_total;
            
            const poIdStr = poId ? poId.toString().toLowerCase() : '';
            const grnStr = GRN ? GRN.toString().toLowerCase() : '';

            return (
              (poIdStr.includes(lowercasedSearchValue)) ||
              (grnStr.includes(lowercasedSearchValue)) ||
              (typeof externalId === 'string' && externalId.toLowerCase().includes(lowercasedSearchValue))||(typeof externalId === 'string' && amount.toString().toLowerCase().includes(lowercasedSearchValue))
            );
        });
    }

    if (Array.isArray(selectedDateRange) && selectedDateRange.length === 2 && selectedDateRange[0] && selectedDateRange[1]) {
        const [startDate, endDate] = selectedDateRange;

        // Normalize dates to the start of the day
        const normalizeDate = (date) => {
            const newDate = new Date(date);
            newDate.setHours(0, 0, 0, 0);
            return newDate;
        };

        const normalizedStartDate = normalizeDate(startDate);
        const normalizedEndDate = normalizeDate(endDate);

        filtered = filtered.filter(item => {
            const itemDate = normalizeDate(new Date(item.due_date));
            return itemDate >= normalizedStartDate && itemDate <= normalizedEndDate;
        });
    }

    setFilteredData(filtered);
};


function handlexit() {
    setFilteredData(data);
}
const convertDate = (dateString) => {
  if (!dateString) return ''; // Return an empty string if dateString is null or undefined
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const [year, month, day] = dateString.split("-");
  const monthName = months[parseInt(month) - 1];
  const dayInt = parseInt(day);
  let suffix = 'th';
  if (dayInt === 1 || dayInt === 21 || dayInt === 31) {
    suffix = 'st';
  } else if (dayInt === 2 || dayInt === 22) {
    suffix = 'nd';
  } else if (dayInt === 3 || dayInt === 23) {
    suffix = 'rd';
  }
  return `${dayInt}${suffix} ${monthName}, ${year}`;
};
const csvConvertDate = (dateString) => {
  if (!dateString) return { date: '', time: '' }; // Return empty strings if dateString is null or undefined

  // Parse the ISO 8601 date string
  const date = new Date(dateString);

  // Extract the day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getUTCFullYear();

  // Extract the hours, minutes, and seconds
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return the date and time separately
  return {
    date: `${day}-${month}-${year}`,
    time: `${hours}:${minutes}:${seconds}`
  };
};


const navigate=useNavigate()
const handleShowInvoice = (data) => {
  localStorage.setItem("receiptdata", JSON.stringify(data));
  localStorage.setItem("previousPath", location.pathname); // Save the current path
  console.log(location,'pathname')
  navigate("/approval/invoice");
};
function generateTextFromPathname() {
  const pathParts = location.pathname.split('/').filter(Boolean); // Split the pathname and remove empty parts
  const lastPart = pathParts[pathParts.length - 1]; // Get the last part of the pathname

  // Capitalize the first letter of the last part
  const capitalizedText = lastPart.charAt(0).toUpperCase() + lastPart.slice(1);

  // Create the final text based on the last part of the path
  const generatedText = `${capitalizedText === 'All' ? 'All Invoices' : capitalizedText} Invoices`;

  // Get the current date and time
  const now = new Date();
  const formattedDate = now.toLocaleString(); // Format the date and time as a readable string

  // Return the generated text with the current date and time
  return `${generatedText} - ${formattedDate}`;
}

const handleShowGRN = (data) => {
  localStorage.setItem("receiptdata", JSON.stringify(data));
  localStorage.setItem("previousPath", location.pathname); // Save the current path
  console.log(location,'pathname')
  navigate("/approval/grn");
};
 
const formatDateAndTime = (timestamp) => {
  const dateObj = new Date(timestamp);

  // Get day, month, and year
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  
  // Get month names array
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[dateObj.getMonth()]; // Get month name
  
  // Add suffix for day (st, nd, rd, th)
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Special rule for 11th to 20th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Format the date as '25th Aug, 2024'
  const date = `${day}${daySuffix(day)} ${month}, ${year}`;

  // Format the time as 'HH:MM:SS'
  const time = dateObj.toTimeString().split(' ')[0];

  return { date, time };
};


  useEffect(() => {
    filterData();
  }, [data, searchValue, selectedDateRange]);

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
 
const getLastDateSigned = (signatures) => {
  if (!signatures || signatures.length === 0) return null;

  let lastDateSigned = null;
  signatures.forEach(signature => {
    const dateSigned = new Date(signature.date_signed);
    if (!lastDateSigned || dateSigned > lastDateSigned) {
      lastDateSigned = dateSigned;
    }
  });

  return lastDateSigned ? lastDateSigned.toISOString().split('T')[0] : null;
};
console.log(filteredData,'filtered data on export')
const exportToCSV = () => {
  const rows = [];
  console.log(filteredData, 'filtered data on export');

  // Define headers for the CSV file
  const headers = [
    'Date Created',
    'PO Number',
    'GRN Number',
    'Reference Number',
    'Net Total',
    'Gross Total',
    'Total Tax',
    'Approval Status'
  ];

  // Dynamically add headers for each signatory (we assume maximum 3 signatories here)
  for (let i = 1; i <= 3; i++) {
    headers.push(`Signatory ${i} Role`);
    headers.push(`Signatory ${i} Name`);
    headers.push(`Signatory ${i} Date Signed`);
    headers.push(`Signatory ${i} Time Signed`);
  }

  filteredData.forEach(item => {
    // Logic for setting the status based on workflow approval/completion
    let status;
    if (item.workflow.completed) {
      status = item.workflow.approved ? 'Approved' : 'Declined';
    } else {
      status = 'Pending';
    }

    // Create base row for each item
    const baseRow = [
      csvConvertDate(item.date_created).date,          // Date Created
      item.grn.purchase_order.po_id,                  // PO Number
      item.grn.grn_number,                            // GRN Number
      item.external_document_id,                      // Reference Number
      item.gross_total,                               // Gross Total
      item.grn.total_value_received,                  // Net Total
      item.total_tax_amount,                          // Total Tax
      status                                          // Approval Status
    ];

    // Add signatories information in the same row
    const signatoryDetails = [];
    item.workflow.signatories.forEach((signatory, index) => {
      const signature = item.workflow.signatures.find(sig => sig.role === signatory);

      // Populate the role, signer's name, date, and time for each signatory
      signatoryDetails.push(
        formatText(signatory), // Signatory Role
        signature ? signature.signer.name : '', // Signatory Name
        signature ? csvConvertDate(signature.date_signed).date : '', // Date Signed
        signature ? csvConvertDate(signature.date_signed).time : '' // Time Signed
      );
    });

    // Push all signatories' data to the base row
    const finalRow = [...baseRow, ...signatoryDetails];
    rows.push(finalRow);
  });

  // Convert the data to CSV format
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += headers.join(',') + '\r\n';

  rows.forEach(row => {
    csvContent += row.join(',') + '\r\n';
  });

  // Create a link to download the CSV
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${generateTextFromPathname()}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    navigate(`/status/${selectedStatus}`);
  };
  console.log(data,'data')
 
  const roles = JSON.parse(localStorage.getItem('roles')) || [];
  const isAdmin = roles.includes("internal_control");
  return (
    <div className='table-container w-full bg-white rounded-xl py-6 flex flex-col'>
         <Modal isVisible={isModalVisible} onClose={handleCloseModal}>
  {selectedInvoice && (
    <div className="relative bg-white rounded-lg shadow ">
      {/* Modal header */}
      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
        <h3 className="text-lg font-semibold text-gray-900 ">
          Invoice Approval Status
        </h3>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={handleCloseModal}
        >
          <svg
            className="w-4 h-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      
      {/* Modal body */}
      <div className="px-4 py-3">
  {selectedInvoice && (
    <>
  <div className="p-4 ">
  {selectedInvoice.workflow.signatories.length > 0 ? (
    <ol className="relative border-s border-gray-200  mb-4">
      {selectedInvoice.workflow.signatories.map((signatory, i) => {
        const signature = selectedInvoice.workflow.signatures.find(
          (sig) => sig.role === signatory
        );
        const isCompleted = selectedInvoice.workflow.completed;
        const isApproved = signature?.approved;

        let status;
        if (isCompleted) {
          if (signature) {
            status = isApproved ? 'Approved' : 'Declined';
          } else {
            status = 'N/A'; // No signature available for this signatory
          }
        } else {
          status = isApproved ? 'Approved' : 'Pending'; // Default to 'Pending' if workflow is not completed
        }
        const dateSigned = signature?.date_signed
        ? ` ${formatDateAndTime(signature.date_signed).date}`
        : ''; // Show date signed if available, otherwise "Not Signed"
        const timeSigned = signature?.date_signed
        ? ` ${formatDateAndTime(signature.date_signed).time}`
        : ''; // Show date signed if available, otherwise "Not Signed"
        return (
          <li class="mb-3 ms-4 w-full ">            
          <span class="absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
              <svg class="w-2.5 h-2.5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"/></svg>
          </span>
          <div className="flex items-center justify-between "> 
            <h3 class=" mb-1 text-base font-semibold text-gray-900 dark:text-white " >{formatText(signatory)}</h3>
            <span  className={` text-sm font-medium  px-2.5 py-0.5 rounded   border ${
                status === 'Approved'
                  ? 'text-green-600 bg-green-100 border-green-300'
                  : status === 'Declined'
                  ? 'text-red-600 bg-red-100 border-red-300'
                  : status === 'N/A'
                  ? 'text-gray-600 bg-gray-100 border-gray-300'
                  : 'text-gray-600 bg-slate-50 border-gray-300'
              }`}>  
              {status}
            </span>
          </div>
     <div className="flex gap-2 items-center">
     
     <div class=" mb-3 text-sm leading-none text-gray-500 dark:text-gray-400">{dateSigned}</div>
    
     <div class=" mb-3 text-xs font-normal leading-none text-gray-500 dark:text-gray-400">{timeSigned}</div>
     </div>
          
        
      </li>
        );
      })}
    </ol>
  ) : (
    <div className="text-center">No Signatories</div>
  )}
</div>


    </>
  )}
</div>

    </div>
  )}
</Modal>

      {recent_invoice && (
        <>
        <p className='title-text font-semibold m-4'>Recent Invoices</p>
        {/* <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                    </svg> */}
        </>
        
        
      )}
{recent_invoice===false &&
 <div className="relative w-[95%] mx-auto flex-col lg:flex-row flex justify-between mb-4 items-center lg:gap-3">
 <div className="relative w-full lg:w-[40%]">
   <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
   <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
       <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
     </svg>
   </div>
 
   <input
     type="text"
     id="simple-search"
     className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-300 block pl-8 py-2 placeholder:text-[0.85rem]"
     placeholder="Search by PO Number, GRN, Amount or Reference.."
     value={searchValue}
     onChange={handleSearchChange}
     required=""
   />
 </div>
 <div className="flex items-center space-x-3 w-full lg:w-auto mt-[0.6rem] lg:mt-0">
   <DateRangePicker
     format="dd-MM-yyyy"
     placeholder="Filter by Date Range"
     style={{ width: 200 }}
     ranges={[]}
     placement="bottomEnd"
     preventOverflow
     onChange={handleDateRangeChange}
     onClean={handlexit}
   />
 
   <button
     id="filterDropdownButton"
     aria-expanded={expand}
     className="w-1/2 md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:outline-none focus:z-10 focus:border-blue-500 focus:ring-0"
     type="button"
     onClick={() => setExpand(!expand)}
   >
     <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="h-4 w-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
       <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
     </svg>
     Status 
     <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
       <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
     </svg>
   </button>
  
   {expand && (
     <div id="filterDropdown" className="absolute z-30 -bottom-44 right-0 w-48 p-3 bg-white rounded-lg shadow ">
       <h6 className="mb-3 text-sm font-medium text-gray-900 e">Status Filter</h6>
       <ul className="space-y-2 text-sm" aria-labelledby="filterDropdownButton">
         {['all', 'pending', 'approved', 'declined'].map((status) => (
           <li key={status} className="flex items-center">
             {/* <input
               id={status}
               type="checkbox"
               className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
             /> */}
             <Link
               to={`/approval/${status}`}
               className="ml-2 text-sm font-medium text-gray-900 no-underline" style={{textDecoration:'none'}}
             >
               {status.charAt(0).toUpperCase() + status.slice(1)}
             </Link>
           </li>
         ))}
       </ul>
     </div>
   )}
 </div>
</div>

}
{isAdmin && recent_invoice==false && <button className="flex self-end w-[90%]  mx-4 md:w-48 text-white rounded-md mb-4 bg-[#067203] items-center justify-center font-medium text-base px-4 gap-3 py-2" onClick={exportToCSV} >
                        <DownloadIcon/>
                        <span>Export as csv</span>
                    </button>}
      <div className="overflow-auto flex flex-col max-h-screen mx-4">
        <table className="md:w-full w-[1024px]">
          <thead className='bg-slate-50'>
            <tr>
              <td className='text-sm font-medium py-3 text-gray-600 pl-4'>SN</td>
              <td className='text-sm font-medium pl-16  text-gray-600'>Date Created</td>
              <td className='text-sm font-medium pl-16 text-gray-600'>PO Number</td>
              <td className='text-sm font-medium pl-16 text-gray-600'>GRN</td>
              <td className='grey-text text-sm font-medium text-gray-600  pl-20'>Ref Number</td>
              <td className='grey-text text-sm font-medium text text-gray-600 pr-28 text-right'>Amount</td>
             
             
              {recent_invoice === false && ( <td className='text-sm font-medium  text-gray-600'>Action</td>)}
            </tr>
          </thead>
          <tbody>
  {filteredData && filteredData.length > 0 ? (
    (recent_invoice ? filteredData.slice(0, 5) : filteredData).map((item, index) => (
      <tr key={index} className="text-sm border-b">
        <td className="text-sm  pl-4">
          {page === 1 ? page + index : size * page - size + 1 + index}
        </td>
        <td className="text-sm  pl-16  py-3">
          {/* {item?.workflow?.signatures?.length>0?convertDate((getLastDateSigned(item.workflow.signatures))):convertDate(item.due_date)} */}
          {convertDate(item.date_created)}
        </td>
        <td className="text-sm  pl-16">{item.grn.purchase_order.po_id}</td>
        <td className="text-sm  pl-16">{item.grn.grn_number}</td>
        <td className="grey-text text-sm  pl-20">{item.external_document_id}</td>
        <td className="grey-text text-sm  pr-28 text-right">
          <NumericFormat 
            value={item.gross_total} 
            displayType="text" 
            thousandSeparator={true} 
            decimalSeparator="." 
            decimalScale={2} 
            fixedDecimalScale 
          />
        </td>
        
        {recent_invoice === false && (
        <td className="pr-2 lg:pr-4 text-center relative">
          <button
            id="benq-ex2710q-dropdown-button"
            data-dropdown-toggle="benq-ex2710q-dropdown"
            className="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none "
            type="button"
            onClick={() => handleToggleList(item)}
          >
            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
          {isListVisible && selectedItem === item && (
            <div className="absolute  top-8 right-1 w-48 bg-white border border-gray-200 rounded-lg z-50">
              <ul className="py-2 text-sm text-gray-700">
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  <button
                    onClick={() => handleShowInvoice(item)}
                    className="block text-gray-700 no-underline no-decoration"
                  >
                    View Invoice
                  </button>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  <button
                    onClick={() => handleShowGRN(item)}
                    className="block text-gray-700 no-underline no-decoration"
                  >
                    View GRN
                  </button>
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  <button
                    onClick={() => handleStatus(item)}
                    className="block text-gray-700 no-underline no-decoration"
                  >
                    View Status
                  </button>
                </li>
                {!item.workflow.completed &&
                  roles.includes(item.workflow.pending_approval_from) &&
                  !allInvoices && (
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700 hover:text-gray-900"
                      onClick={() => {
                        onButtonClick(item);
                        setIsListVisible(false);
                      }}
                    >
                     <button className='block'>Approve/Decline</button>
                    </li>
                  )}
              </ul>
            </div>
          )}
        </td>
        )}
      </tr>
    ))
  ) : (
    <tr className="w-full text-center">
      <td colSpan="8" className="py-4">No Invoice found!</td>
    </tr>
  )}
</tbody>



        </table>
        {recent_invoice &&filteredData && filteredData.length > 0&&
        <div className='self-end flex gap-1 items-center my-4 mx-10'>
          <span>See more</span>
         <Link to='/approval/pending'><Arrowright/></Link> 
          </div>}
          {!recent_invoice && filteredData && filteredData.length > 0 &&
  <Pagination
    totalPages={total}
    entriesPerPage={size}
    totalEntries={count}
  />
}

    
        {/* {recent_invoice===false&&filteredData && filteredData.length > 0 && (
          <div className="pagination-container flex justify-between mt-4 mb-5 px-3">
            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                    </svg>
            <div>
              <label>Items per page:</label>
              <select className='mx-3 px-1 select-box' onChange={handleChangeItemsPerPage} value={itemsPerPage}>
               
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </div>
            <div className='flex items-center justify-between px-2'>
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                <Chevron_left className='bg-gray-100 rounded-md' />
              </button>
              <div className='px-2 -mt-1'>
                {currentPage}/{totalPages}
              </div>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                <Chevron_right className='bg-gray-100 rounded-md' />
              </button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default TableComponent;
