import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import PageTitle from '../components/PageTitle';
import { Form } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import {ReactComponent as Addition} from '../assets/images/addition.svg'
import {ReactComponent as Cloud} from '../assets/images/cloud.svg'
import {ReactComponent as Edit} from '../assets/images/edit.svg'
import PasswordResetModal from '../components/PasswordRest';
import Loader from '../components/Loader';

const ProfileSettings = () => {
  
const token = localStorage.getItem('access')

  const [logoBase64, setLogoBase64] = useState(null);
  const [vendordetails, setVendorDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState(vendordetails?.vendor_settings?.invoice_color);
  const [showPicker, setShowPicker] = useState(false);
  const firstSignIn = localStorage.getItem("firstSignIn") === "true";
 
  const handleColorChange = (color) => {
    console.log(color)
    setSelectedColor(color.hex);  // Store the hex value
  };
  
  const [logoPreview, setLogoPreview] = useState(null);
const [vendorname,setVendorName]=useState('')
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentPassword, setCurrentPassword] = useState('');
const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');

const openModal = () => {
  setIsModalOpen(true);
};

const closeColorModal = () => {
  setShowPicker(false)
setSelectedColor(vendordetails?.vendor_settings?.invoice_color)
};
const closeModal = () => {
  setIsModalOpen(false);
  setCurrentPassword('');
  setNewPassword('');
  setConfirmPassword('');
};

const [errorMessage, setErrorMessage] = useState('');
const [successMessage, setSuccessMessage] = useState('');

const handlePasswordUpdate = async () => {
  setLoading(true);
  setErrorMessage(''); // Clear error messages before the request
  setSuccessMessage(''); // Clear success messages before the request

  const payload = {
    old_password: currentPassword,
    new_password: newPassword,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/password-change`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Add content type for JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      // Handle success response
      const data = await response.json();
      setSuccessMessage('Password updated successfully!');
      setLoading(false);
      closeModal(); // Close the modal on success
    } else {
      // Handle error response
      const errorData = await response.json();
      setErrorMessage( 'Failed to update password.');
      setLoading(false);
    }
  } catch (error) {
    setErrorMessage('Something went wrong. Please try again.');
    setLoading(false);
  }
};



const navigate=useNavigate()
const handleThemeSubmit = () => {
  const uploadedData = {
    vendor_settings: {
      invoice_color: selectedColor
    },
    // phone: vendorname // Replace "phone_number" with the actual phone number
  };
console.log(uploadedData)
  Swal.showLoading(); // Display loading indicator

  axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/profile`,
      uploadedData, // Pass uploadedData as the second argument
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then((response) => {
      Swal.close(); // Close loading indicator
      // Display success message
      Swal.fire({
        title: "Success",
        text: "Profile updated successfully!",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "OK"
      }).then((result) => {
        if (result.isConfirmed) {
          if (firstSignIn) {
            navigate('/');
            localStorage.setItem("firstSignIn", "false");
          }
          setShowPicker(false)
        }
      });
    })
    .catch((error) => {
      Swal.close(); // Close loading indicator
      // Display error message
      Swal.fire({
        title: "Error",
        text: "An error occurred while processing your request.",
        icon: "error",
        confirmButtonText: "Close",
        footer: "<a href='#'>Click here to navigate</a>"
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle error confirmation
        }
      });
    });
};

useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('access'); // Assuming token is stored in localStorage
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVendorDetails(response.data.data);
        setVendorName(response.data.data.phone)
        localStorage.setItem(
          'vendorDetails',
          JSON.stringify(response.data.data)
        );
      } catch (error) {
        console.error('Error:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  
}, []);

const [uploadReady, setUploadReady] = useState(false);
const [imageModalOpen, setImageModalOpen] = useState(false);

const handleLogoSubmit = () => {
  const uploadedData = {
    vendor_settings: {
      logo: logoBase64
    }
  };

  Swal.showLoading(); // Display loading indicator

  axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/profile`,
      uploadedData, // Pass uploadedData as the second argument
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then((response) => {
      Swal.close(); // Close loading indicator
      // Display success message
      Swal.fire({
        title: "Success",
        text: "Profile updated successfully!",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "OK"
      }).then((result) => {
        if (result.isConfirmed) {
          if (firstSignIn) {
            navigate('/');
            localStorage.setItem("firstSignIn", "false");
          }
          setImageModalOpen(false);
        }
      });
    })
    .catch((error) => {
      Swal.close(); // Close loading indicator
      // Display error message
      Swal.fire({
        title: "Error",
        text: "An error occurred while processing your request.",
        icon: "error",
        confirmButtonText: "Close",
        footer: "<a href='#'>Click here to navigate</a>"
      });
    });
};


const handleLogoUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      setLogoPreview(reader.result);  // Set image preview
      setLogoBase64(reader.result);   // Store base64 string for submission
      setUploadReady(true);           // Enable save button
      setImageModalOpen(true);        // Open modal
    };
    reader.readAsDataURL(file);  // Trigger file reading as data URL
  }
};


const closeImageModal = () => {
  setLogoPreview(vendordetails?.vendor_settings?.logo)         // Reset preview on cancel
  setUploadReady(false);         // Reset upload state
};

// Handle color upload
console.log(vendordetails?.vendor_settings?.invoice_color ,'invoice color')
  return (
    <div>
    <Header />
   <div className="flex main-body">
 <div className="sidebar hidden md:block">
   <Sidebar />
 </div>
 <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3">
          <PageTitle pagetitle="Profile" />
          <div className="p-4 mx-auto bg-white rounded-lg mt-4  ">
  <div className="flex flex-col lg:flex-row  mt-auto p-3 lg:p-8">
    {loading && <Loader/>}
  <div className="w-full md:w-1/5">
  <div 
      className="flex flex-col items-center justify-center h-36 w-36 rounded-full bg-gray-50 relative  "
    >
      <img 
        src={logoPreview || vendordetails?.vendor_settings?.logo} 
        alt="Vendor Logo" 
        className="w-32 h-32 object-cover self-center rounded-full" 
      />
      <label className="flex flex-col items-center cursor-pointe rounded-full z-30 absolute -bottom-4 right-0">
        <Edit className='cursor-pointer ' title='Change logo'/>
        <button
          onClick={() => setImageModalOpen(true)}
        />
      </label>
    </div>
    </div>

    {/* Upload Logo Modal */}
    {imageModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="relative bg-white text-black rounded-lg shadow-lg p-8 w-full max-w-md">
        {/* Modal Close Button */}
        <button
          className="absolute top-5 right-7 text-gray-400 hover:text-black px-[0.7rem] py-[0.3rem] border border-gray-500 rounded"
          onClick={() => setImageModalOpen(false)}
        >
          ✕
        </button>

        {/* Modal Title */}
        <div className="text-lg font-semibold mb-2">Change your company logo</div>
        <p className="text-sm text-gray-500 mb-4">
          This will be shown on your profile and invoice.
        </p>

        {/* Image and Upload Section */}
        <div className="flex flex-col items-center mb-4 gap-4">
          {/* Image Preview Section */}
          <div className="relative w-36 h-36 rounded-full overflow-hidden bg-slate-50 flex items-center justify-center">
            <img
              src={ logoPreview || vendordetails?.vendor_settings?.logo}
              alt="Vendor Logo"
              className="w-full h-full object-cover transform"
              // style={{ transform: `scale(${zoom})` }} // Apply zoom here
            />
          </div>

          {/* Zoom Slider */}
          {/* <div className="flex flex-col items-center w-full">
            <label htmlFor="zoomRange" className="text-sm text-gray-500 mb-1">
              Zoom
            </label>
            <input
              id="zoomRange"
              type="range"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
              className="w-3/4"
            />
          </div> */}

          {/* Upload Button Section */}
          <div className="flex flex-col items-center justify-center border-dashed border-2 border-gray-400 rounded-md py-4 px-12 cursor-pointer">
            <label className="cursor-pointer flex flex-col">
              <div className="flex items-center justify-center">
                <Cloud className="h-8 w-8 bg-gray-100 rounded-full" />
              </div>
              <span className="text-blue-500 underline">Click to upload</span>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleLogoUpload}
              />
            </label>
          </div>
        </div>

        {/* Action Buttons */}
       {uploadReady &&
        <div className="flex justify-between w-full mt-8">
        <button
          className="text-blue-600 bg-gray-200 px-4 py-2 rounded"
          onClick={closeImageModal}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleLogoSubmit} // Save zoomed image
        >
          Save changes
        </button>
      </div>}
      </div>
    </div>
  )}


    {/* Right Section (User Info) */}
    <div className="w-full lg:w-4/5 rounded-md mt-4 lg:mt-0">
  {/* Grid for form items, responsive across screens */}
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
    {/* Vendor Name */}
    <div>
      <label className="block text-sm font-medium text-gray-700">Vendor</label>
      <input 
        type="text" 
        value={vendordetails?.vendor_name}
        className="mt-1 bg-gray-50 block w-full border border-gray-300 rounded-md p-2"
        readOnly 
      />
    </div>
    {/* Mobile Number */}
    <div>
      <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
      <input 
        type="text" 
        value={vendorname}
        readOnly
        className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-blue-200 bg-gray-50" 
      />
    </div>
  </div>

  {/* Vendor Email and Vendor ID */}
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
    {/* Vendor Email */}
    <div>
      <label className="block text-sm font-medium text-gray-700">Vendor Email</label>
      <input 
        type="email" 
        value={vendordetails?.email}
        readOnly
        className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 bg-gray-50 focus:ring-blue-200"
      />
    </div>
    {/* Vendor ID */}
    <div>
      <label className="block text-sm font-medium text-gray-700">Vendor ID</label>
      <input 
        type="text" 
        value={vendordetails?.username}
        readOnly
        className="mt-1 block w-full border border-gray-300 bg-gray-50 rounded-md p-2"
      />
    </div>
  </div>

  {/* Invoice Theme Color Picker */}
  <div className="mt-6">
    <div className="flex justify-between items-center">
      <label htmlFor="vendor" className="text-sm font-medium text-gray-700">
        Invoice Theme
      </label>
      <div className="flex items-center">
        <Edit onClick={() => setShowPicker(!showPicker)} className="cursor-pointer" title="Change color"/>
        <span className="ml-1">Edit</span>
      </div>
    </div>
    <div className="w-full h-10 rounded p-2 justify-end bg-white border border-gray-300 my-2">
      <input
        className="w-full h-full rounded-md"
        style={{
          backgroundColor: selectedColor || vendordetails?.vendor_settings?.invoice_color || "#ffffff",  // Use hex value
        }}
      />
    </div>
  </div>

  {/* Change Password Button */}
  <div className="pt-4 flex flex-col">
    <button 
      onClick={openModal}
      className="self-end bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-200">
      Change Password
    </button>
  </div>

  {/* Password Reset Modal */}
  {isModalOpen && (
    <PasswordResetModal closeModal={closeModal} token={token} />
  )}
</div>

  </div>

  {/* Divider */}

</div>

            {showPicker && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
    <div className="relative bg-white text-black rounded-lg shadow-lg p-8 w-full max-w-md">
      {/* Modal Close Button */}
      <button
        className="absolute top-5 right-7 text-gray-400 hover:text-black px-[0.7rem] py-[0.3rem] border border-gray-500 rounded"
        onClick={closeColorModal}
      >
        ✕
      </button>

      {/* Flex container for Color Picker and Preview */}
      <div className="flex items-center gap-4 mb-4">
        {/* Color Picker */}
        <div className="w-full">
          <SketchPicker
            onChange={handleColorChange}
            color={selectedColor}
          />
        </div>

        {/* Color Preview Box */}
        <div
          className="w-16 h-16 rounded-md border"
          style={{
            backgroundColor: selectedColor || vendordetails?.vendor_settings?.invoice_color || "#ffffff",  // Use hex value
          }}
        ></div>
      </div>

      {/* Sample Text in Selected Color */}
      <p
        className="text-center text-lg font-semibold mb-4"
        style={{
          color: selectedColor || vendordetails?.vendor_settings?.invoice_color || "#ffffff",  // Use hex value
        }}
      >
        This is a sample text in the selected color.
      </p>

      {/* Action Buttons */}
      <div className="flex justify-between w-full mt-4">
        <button
          className="text-blue-600 bg-gray-100 px-4 py-2 rounded"
          onClick={closeColorModal} // Cancel button to close the modal
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleThemeSubmit} // Upload button to handle color submission
        >
          Update Color
        </button>
      </div>
    </div>
  </div>
)}


            {/* <button
              className="self-end mr-0 rounded py-2 px-5 mt-4 mx-4 text-white font-semibold bg-blue-700"
              onClick={handleSubmit}
            >
              Save Settings
            </button> */}
          
        </div>
        
      </div>
    </div>
  );
};

export default ProfileSettings;
