import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import ApprovalStatusImage from '../assets/images/image 12.png';
import ErrorImage from '../assets/images/errorimage.png';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthSpinner from '../components/Authspinner';
import { setNavigationState } from '../redux/actions/userActions';
import AuthenticationPages from '../components/AuthenticationPages';
import { ReactComponent as Envelope } from '../assets/images/Group.svg';
import { ReactComponent as Phone } from '../assets/images/Phone.svg';
import axios from 'axios';

const Verification = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [responseError, setResponseError] = useState(false);
  const [inputError, setInputError] = useState('');
  
  const dispatch = useDispatch();
  const location = useLocation();
  
  const status = location?.state?.state?.status || '';
  const navigationState = useSelector((state) => state.navigation);
  const { actionType } = navigationState;

  const handleClose = () => {
    setShowModal(false);
    setInputValue('');
    setModalMessage('');
    setSelectedOption('');
    // setResponseError(false);
  };

  const validateInput = () => {
    let isValid = true;

    if (selectedOption === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputValue)) {
        setInputError('Please enter a valid email address.');
        isValid = false;
      } else {
        setInputError('');
      }
    }

    if (selectedOption === 'phone') {
      const phoneRegex = /^[0-9]{10,15}$/; // Adjust the range based on phone number format.
      if (!phoneRegex.test(inputValue)) {
        setInputError('Please enter a valid phone number.');
        isValid = false;
      } else {
        setInputError('');
      }
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!selectedOption || !validateInput()) {
      return;
    }
  
    setLoading(true);
  
    let endpoint = '/api/v1/vendor/onboard/new'; // Default endpoint
    let payload = {
      type: selectedOption,
      id: inputValue,
    };
  
    if (actionType === 'forgotPassword') {
      endpoint = '/api/v1/vendor/password-reset'; // Change to password reset endpoint
      if (selectedOption === 'email') {
        payload = {
          email: inputValue, // Only send email for forgot password
        };
      } else {
        // If the user selects phone number, handle it accordingly (you might want to display an error or ignore it)
        setInputError('Please use email for password reset.');
        setLoading(false);
        return;
      }
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload // Send the constructed payload
      );
  
      if (response) {
        setResponseError(false);
        setModalMessage(response?.data?.message);
  
        if (actionType === 'signup') {
          dispatch(setNavigationState({ actionType: 'signup' }));
        } else if (actionType === 'forgotPassword') {
          dispatch(setNavigationState({ actionType: 'forgotPassword' }));
        }
      }
    } catch (error) {
      if (error.response) {
        setResponseError(true);
        setModalMessage(`${error.response.data.message}`);
      } else {
        setResponseError(true);
        setModalMessage('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  };
  

  return (
    <div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <div className='d-flex align-items-center flex-column justify-content-center'>
            {responseError ? (
              <img src={ErrorImage} alt='Error' className='error-image h-16' />
            ) : (
              <img src={ApprovalStatusImage} alt='Approval Status' />
            )}
            <div className={responseError ? 'verify-error' : ''}>
              {responseError ? 'Error!' : 'Success'}
            </div>
            {modalMessage && (
              <div className='approval-status-message text-center'>
                {modalMessage}
              </div>
            )}
            <Button
              className={`my-2 ${responseError ? 'bg-red-500' : 'bg-green-600'} text-white py-2 px-4 rounded-md hover:${responseError ? 'bg-red-600' : 'bg-green-700'}`}
              onClick={handleClose}
            >
              {responseError ? 'Close' : 'Ok'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AuthenticationPages FormTitle={`${actionType === 'signup' ? 'Register' : actionType === 'forgotPassword' ? 'Change Password' : ''}`}>

        <Form.Group>
          <div className="font-bold text-lg leading-11 tracking-normal text-left underline">
            Step 1 of 2: Vendor Verification
          </div>
          <div className="border-l-2 border-solid text-sm text-justify p-2 my-4 border-blue-700 bg-gray-100 text-gray-700">
            Email verification is necessary to ensure your email address or phone number has been confirmed and is ready for use. Please contact{' '}
            <Link to="#" className='underline'>
              admin@vendorportal.com
            </Link>{' '}
            for any registration assistance.
          </div>

          <Form.Label className='text-[#393838] text-sm font-semibold'>
            Select Verification Option
          </Form.Label>

          <Form.Select
            className='mb-3 custom-select row-container2 appearance-none border-l-3 border-gray-900 w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:border-2 focus:border-gray-700 shadow-none'
            aria-label='Select Verification Type'
            value={selectedOption}
            onChange={(e) => {setSelectedOption(e.target.value);
              setInputError('')
            }}
          >
            <option value=""></option>
            <option value="email">Email</option>
            <option value="phone">Phone Number</option>
          </Form.Select>

          {selectedOption && (
            <Form.Group className='position-relative'>
              <Form.Control
                type={selectedOption === 'email' ? 'email' : 'tel'}
                name='q'
                placeholder={`Enter ${selectedOption === 'email' ? 'Email' : 'Phone Number'}`}
                className='mb-3 custom-select row-container2 appearance-none border-l-3 border-gray-900 w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:border-[#115FF5] shadow-none'
                value={inputValue}
                onChange={(e) => {setInputValue(e.target.value)
                  setInputError('')
                }}
                required
              />
              <div
                className="position-absolute"
                style={{
                  right: '24px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {selectedOption === 'email' ? <Envelope /> : <Phone />}
              </div>
              
            </Form.Group>
            
          )}
{inputError && <div className=' mb-2 text-red-600'>{inputError}</div>}
          <Button
            className={`authentication-button mb-1 ${loading && 'd-flex align-items-center justify-content-center gap-1'}`}
            type='submit'
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading && <AuthSpinner />} <span>{loading ? '' : 'Send Verification'}</span>
          </Button>
          <p className='mt-2 flex items-center gap-1'>
           {status === 'forgotPassword' ? 'Remember Password' : 'Have an account'} ?{' '}
            <Link className='text-blue-700 underline' to='/sign-in'>
              Login
            </Link>{' '}
          </p>
        </Form.Group>
      </AuthenticationPages>
    </div>
  );
};

export default Verification;
