import axios from 'axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    SET_VERIFICATION_CODE,
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_FAILURE,
    RESET_SELECTED_ITEMS

} from '../constants/userConstants'


export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        /*const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        
        const { data } = await axios.post(
            '/api/users/login/',
            { 'username': email, 'password': password },
            config
        )
*/      const data= {
                        "email":email,
                        "password":password
                    }
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
                
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
        console.log(data)

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const logout = () => (dispatch) => {
    localStorage.clear()
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    dispatch({ type: RESET_SELECTED_ITEMS })
}
export const setVerificationCode = (code) => ({
    type: SET_VERIFICATION_CODE,
    payload: code,
  });
  
  export const verifyCodeSuccess = () => ({
    type: VERIFY_CODE_SUCCESS,
  });
  
  export const verifyCodeFailure = (error) => ({
    type: VERIFY_CODE_FAILURE,
    payload: error,
  });
  
  export const verifyCode = (enteredCode) => async (dispatch) => {
    // Assume you have an API endpoint for code verification
    const apiUrl = 'https://api.example.com/verify-code';
  
    try {
      // Dummy API call for now
      // Replace this with an actual API call using Axios
      const response = await axios.post(apiUrl, { code: enteredCode });
  
      // Dummy response for now
      // Replace this with the logic to handle the actual API response
      const responseData = { success: true };
  
      if (responseData.success) {
        dispatch(verifyCodeSuccess());
      } else {
        dispatch(verifyCodeFailure('Invalid code. Please try again.'));
      }
    } catch (error) {
      // Handle API call error
      dispatch(verifyCodeFailure('Error verifying code. Please try again.'));
    }
  };

  export const setNavigationState = (data) => ({
    type: 'SET_NAVIGATION_STATE',
    payload: data,
  });
  
/*export const register = (name, email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/api/users/register/',
            { 'name': name, 'email': email, 'password': password },
            config
        )

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/users/${id}/`,
            config
        )

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
*/


