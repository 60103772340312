import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../assets/images/arrowLeft.svg";
import makeAnimated from "react-select/animated";
// import { styled } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';
import Select from "react-select";
import axios from "axios";
import { DatePicker } from "rsuite";
import { format,addDays,isWeekend } from "date-fns";
import { NumericFormat } from "react-number-format";
import { InputGroup } from "react-bootstrap";
const CreateInvoiceStep4 = ({
	handlePrevious,
	currentPage,
	totalPages,
	handleNext,
	vendorInfo,
	selectedPO,
}) => {
	const animatedComponents = makeAnimated();
	const handleNextPage = () => {
		localStorage.setItem("ReferenceNumber", referenceNumber);
		localStorage.setItem("DueDate", format(new Date(), 'yyyy-MM-dd'));
		localStorage.setItem(
			"selectedItemswithduties",
			JSON.stringify(selectedItems)
			
		);
		setSelectedItems(selectedItems)
		console.log(selectedItems,'selecteddd')
		handleNext();
	};
	const token = localStorage.getItem('access')
	const [referenceNumber, setReferenceNumber] = useState(null);
	const referenceNumberNull = referenceNumber && referenceNumber.length > 0;
	console.log(referenceNumber,'referen')
	useEffect(() => {
		const savedReferenceNumber = localStorage.getItem('ReferenceNumber');
		if (savedReferenceNumber) {
		  setReferenceNumber(savedReferenceNumber);
		}
	  }, []);
	
	  const [errorMessage, setErrorMessage] = useState('');

	  const onReferenceNumberChanged = (event) => {
		const { value } = event.target;
	  
		// Regular expression to check if the input is a valid number or empty (for deletion)
		const isValidNumber = /^[0-9]*$/.test(value);
	  
		if (isValidNumber) {
		  setReferenceNumber(value);
		  setErrorMessage(''); // Clear error message if input is valid or empty
		} else {
		  setErrorMessage('Invalid input: Please enter a number.');
		}
	  };

	
	  useEffect(() => {
		localStorage.setItem('ReferenceNumber', referenceNumber?referenceNumber:'');
	  }, [referenceNumber]);
	
	  // Remove referenceNumber from local storage before page unloads
	  useEffect(() => {
		const clearLocalStorage = () => {
		  localStorage.removeItem('ReferenceNumber');
		};
	
		window.addEventListener('beforeunload', clearLocalStorage);
	
		return () => {
		  window.removeEventListener('beforeunload', clearLocalStorage);
		};
	  }, []);
	  const addWorkingDays = (startDate, numberOfDays) => {
		let resultDate = startDate;
		let daysAdded = 0;
	  
		while (daysAdded < numberOfDays) {
		  resultDate = addDays(resultDate, 1);
		  if (!isWeekend(resultDate)) {
			daysAdded++;
		  }
		}
	  
		return resultDate;
	  };
	  const today = new Date();
	  const futureDate = addWorkingDays(today, 30);
	  const [dueDate, setDueDate] = useState(futureDate);
	  

	//   useEffect(() => {
	// 	  const today = new Date();
	// 	  const targetDate = addWorkingDays(today, 30);
	// 	  const formattedDate = targetDate.toISOString().split('T')[0];
	// 	  setDueDate(formattedDate);
	//   }, []);
  
	
	
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: "white",
			padding: "1px",
			width: "240px",
			borderColor: "#ccc",
			marginTop: "7px",
			backgroundColor: state.isDisabled ? "#CCC" : "white",
			cursor: state.disabled ? "not-allowed" : "",
		}),

		input: (styles) => ({ ...styles }),
	};

	const SelectedItems = JSON.parse(localStorage.getItem("SelectedItems")) || {
		filteredData: [],
	};
	const [selectedItems, setSelectedItems] = useState(
		SelectedItems.filteredData
	);
	
	// useEffect(() => {
	// 	const savedSelectedItems = localStorage.getItem('selectedItems');
	// 	if (savedSelectedItems) {
	// 	  setSelectedItems(JSON.parse(savedSelectedItems));
	// 	}
	//   }, []);
	
	  useEffect(() => {
		localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
	  }, [selectedItems]);
	
	  // Remove selectedItems from local storage before page unloads
	  useEffect(() => {
		const clearLocalStorage = () => {
		  localStorage.removeItem('selectedItems');
		  localStorage.removeItem('selectedRows');
		};
	
		window.addEventListener('beforeunload', clearLocalStorage);
	
		return () => {
		  window.removeEventListener('beforeunload', clearLocalStorage);
		};
	  }, []);
	
	console.log(selectedItems.grn_line_item
		,'sellll')
	

	const [selectedRow, setSelectedRow] = useState(
		Array.from({ length: selectedItems.length }, () => [])
	);
	const handleOtherInputChange = (index, value) => {
		// Create a copy of the selectedItems array
		const updatedSelectedItems = [...selectedItems];

		// Update the discount value of the item at the specified index
		updatedSelectedItems[index] = {
			...updatedSelectedItems[index],
			discountValue: value,
		};
		// console.log(updatedSelectedItems[index]); // Corrected typo here

		// Set the updated array as the new state
		setSelectedItems(updatedSelectedItems);
	
	};

	useEffect(() => {
		setSelectedRow(
			Array.from({ length: SelectedItems.filteredData.length }, () => [])
		);
	}, []);

	// console.log(selectedItems);
	// console.log(itemsWithDiscount);
	// console.log(itemsWithTax);
	// console.log(isTaxOrDiscountApplied);

	const [error, setError] = useState(Array(selectedItems.length).fill(null));

	const handlePartialQuantityChange = (index, value) => {
		const numberRegex = /^\d*\.?\d*$/;

		if (value === "" || value === undefined) {
			const updatedItems = [...selectedItems];
			updatedItems[index].partialQuantity = value;
			setSelectedItems(updatedItems);
			setError((prevErrors) => {
				const updatedErrors = [...prevErrors];
				updatedErrors[index] = null;
				return updatedErrors;
			});
			return;
		}

		if (numberRegex.test(value)) {
			const parsedValue = parseFloat(value);
			if (parsedValue <= selectedItems[index].delivered_quantity) {
				const updatedItems = [...selectedItems];
				updatedItems[index].partialQuantity = parsedValue;
				setSelectedItems(updatedItems);
				// console.log(updatedItems, "HHHHH");
				setError((prevErrors) => {
					const updatedErrors = [...prevErrors];
					updatedErrors[index] = null;
					return updatedErrors;
				});
			} else {
				setError((prevErrors) => {
					const updatedErrors = [...prevErrors];
					updatedErrors[index] =
						"Value cannot be greater than quantity delivered";
					return updatedErrors;
				});
			}
		} else {
			setError((prevErrors) => {
				const updatedErrors = [...prevErrors];
				updatedErrors[index] = "Please input a valid number";
				return updatedErrors;
			});
		}
	};

	const [taxRates, setTaxRates] = useState([]);
	const isAnyDiscountInvalid = selectedItems.some(item => item.discountStatus && (!item.discountType|| !item.discountValue));

	const isAnyTaxInvalid = selectedItems.some(item => item.taxStatus && !item.selectedOptions);

	useEffect(() => {
		const fetchTaxRates = async () => {
			try {
				console.log(token,'token')
				const response = await axios.get(
					`${process.env.REACT_APP_BASE_URL}/api/v1/surcharges`,
					{
						headers: {
						  Authorization: `Bearer ${token}`,
						},}
				
				)
				// console.log(response.data.data, "dataa");
				setTaxRates(
					response.data.data
						.map((item) => ({
							value: item.id,
							label: item.type,
							rate: item.rate,
						}))
						.filter((item) => item.rate > 0)
				);
			} catch (error) {
				console.error("Error fetching tax rates:", error);
			}
		};

		fetchTaxRates();
	}, []);
	// console.log(selectedItems)
useEffect(()=>{
	console.log(selectedItems, 'I AM SELECTED--')
},[selectedItems])
	return (
		<div>
			<div className="pb-5 box-shadow bg-white flex flex-col">
				<div className="flex p-3 justify-between bg-blue-800 text-base text-white">
					<div className="flex gap-2">
						<button
							className="border-0 bg-transparent"
							onClick={handlePrevious}
						>
							<ArrowLeft />
						</button>
						<div className="flex">Input invoice reference number</div>
					</div>
					<span>
						Step {currentPage} of {totalPages}
					</span>
				</div>
				<div className="flex flex-col md:flex-row justify-between w-full md:px-12 mx-2 md:gap-8 gap-3 my-4 mb-2">
					<div className=" flex-col w-full md:self-center">
						<label htmlFor="vendor" className="mb-0  text-sm font-semibold">
							Vendor
						</label>
						<input
							type="text"
							id="vendor"
							value={vendorInfo}
							className="mt-2 block p-2 font-normal   text-sm  w-full px-3 border h-10  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
						/>
					</div>
					<div className="w-full flex-col items-center">
						<label htmlFor="vendor" className="mb-0   text-sm font-semibold">
							PO Number
						</label>
						<input
							type="text"
							id="vendor"
							value={selectedPO}
							className="mt-2 block p-2 font-normal  text-sm w-[95%] border h-10 mx-auto md:w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
						/>
					</div>
				</div>

				<div className="md:mx-12 mx-2 flex flex-col justify-center px-2 mt-5 overflow-auto">
					<table>
						<thead className=" bg-blue-800 text-white font-normal">
							<tr>
								<th className="py- pl-2 ">GRN Number</th>
								<th className="py- pl-2">Item Description</th>
								<th className="py-2 text-right pr-4 ">Quantity </th>
								<th className="py-2 text-right pr-8">Amount</th>
								<th className="py-2 text-right pr-8">Tax</th>
								<th className="py-2 text-right pr-8">Discount</th>
							</tr>
						</thead>
						<tbody>
							{selectedItems.map((item, index) => (
								<tr key={index}>
									<td className="py-1">
										<input
											type="text"
											id={`quantity-${index}`}
											className="mt-2 block p-2 h-10 font-normal border  text-sm px-3  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
											value={item.grn_number}
											readOnly
											style={{width:'120px'}}
										/>
									</td>
									<td className="py-1">
										<input
											type="text"
											id={`quantity-${index}`}
											className="mt-2 block p-2 h-10 font-normal border text-sm px-3  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
											value={item.purchase_order_line_item.product_name}
											readOnly
											
										/>
									</td>
									<td className="py-1">
									{/* <input
    type="text"
    id={`quantity-${index}`}
    readOnly
	style={{width:'120px'}}
    className={`mt-2 block p-2 h-10 font- border border-gray-700 bg-white text-sm px-3 rounded-md focus:outline-none focus:ring-indigo-500 ${
        error[index]
            ? "border-red-500 focus:border-red-500"
            : "focus:border-blue-600"
    } appearance-none focus:border-2`}
    placeholder="Enter Quantity"
    value={parseFloat(item.quantity_received).toFixed(2)} // Directly use the formatted value
/>

										{error[index] && (
											<span className="text-sm text-red-500 mt-1">
												{error[index]}
											</span>
										)} */}
										<NumericFormat
                                                                   value={item.quantity_received}
                                                                    displayType="input"
                                                                    thousandSeparator={
                                                                      true
                                                                    }
																	readOnly
                                                                    decimalSeparator="."
                                                                    fixedDecimalScale
																	className="mt-2 text-right block w-32 p-2 h-10 font-normal border text-sm px-3  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                                                                  />
									</td>
									<td className="py-1">
                                                                  <NumericFormat
                                                                   value={item.net_value_received? item.net_value_received: 0.00}

                                                                    displayType="input"
																	readOnly
                                                                    thousandSeparator={
                                                                      true
                                                                    }
                                                                    decimalSeparator="."
                                                                    fixedDecimalScale
																	className="mt-2 text-right w-40 block p-2 h-10 font-normal border text-sm px-3  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                                                                  />
									</td>
										{/* <Select
											closeMenuOnSelect={true}
											components={animatedComponents}
											isMulti
											styles={colourStyles}
											options={taxRates}
											isDisabled={!item.taxStatus}
											value={selectedItems[index]?.selectedOptions || []}
											onChange={(selectedOptions) => {
												setSelectedItems((prevItems) => {
													const updatedItems = [...prevItems];
													updatedItems[index] = {
														...updatedItems[index],
														selectedOptions: selectedOptions,
													}; // Append selectedOptions to selectedItems
													console.log(selectedItems,'rtrt')
													return updatedItems;
												});
											}}
										/> */}
											<td className="py-1">
                                                                  <NumericFormat
                                                                   value={item.purchase_order_line_item.metadata.TaxAmount
																	? parseFloat(item.purchase_order_line_item.metadata.TaxAmount)
																	: 0.00}

                                                                    displayType="input"
																	readOnly
                                                                    thousandSeparator={
                                                                      true
                                                                    }
                                                                    decimalSeparator="."
                                                                    fixedDecimalScale
																	className="mt-2 text-right block p-2 h-10 font-normal border text-sm px-3 w-40 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                                                                  />
									</td>

									{/* <td className="py-1">
    <InputGroup style={{ width: "180px",marginTop:'5px' }} className={`${!item.discountStatus ? 'bg-[#ccc] rounded-md cursor-not-allowed h-10' : ''}`} disabled={!item.discountStatus}>
        <input
            type="text"
            style={{ width: "75%" }}
            id={`other-description-${index}`}
            placeholder="Enter discount.."
            className={`p-3 block px-3 h-10  font-normal text-sm border rounded-md border-[#ccc] focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2 ${!item.discountStatus ? " bg-[#ccc] cursor-not-allowed border-0" : ""}`}
            value={selectedItems[index]?.discountValue !== undefined ? selectedItems[index].discountValue : ""}
            onChange={(e) => handleOtherInputChange(index, e.target.value)}
            disabled={!item.discountStatus}
        />
         <InputGroup.Text
            className={`h-10 mt-2 text-lg flex justify-center text-gray-700 ${!item.discountStatus?'bg-[#ccc] cursor-not-allowed':''}`}
            style={{ width: "30%" }}
       / > 
            <select
                className={` focus:outline-none rounded-md h-10 text-lg flex justify-center border border-[#ccc] text-gray-700 ${!item.discountStatus?'bg-[#ccc] cursor-not-allowed border-0':''}`}
                value={selectedItems[index]?.discountType || ""}
				style={{ width: "25%" }}
                onChange={(e) => {
                    const selectedOption = e.target.value;
                    setSelectedItems((prevItems) => {
                        const updatedItems = [...prevItems];
                        updatedItems[index] = {
                            ...updatedItems[index],
                            discountType: selectedOption,
                        };
                        return updatedItems;
                    });
                }}
                disabled={!item.discountStatus}
            >
                <option></option>
                <option value="%">%</option>
                <option value="&#8358;">&#8358; </option>
            </select>
        {/* </InputGroup.Text> 
    </InputGroup>
</td> */}
	<td className="py-1">
	<NumericFormat
                                                                   value={item.purchase_order_line_item.metadata.DiscountAmount
																	? parseFloat(item.purchase_order_line_item.metadata.DiscountAmount)
																	: 0.00}
                                                                    displayType="input"
                                                                    thousandSeparator={
                                                                      true
                                                                    }
																	readOnly
                                                                    decimalSeparator="."
                                                                    fixedDecimalScale
																	className="mt-2 text-right w-40 block p-2 h-10 font-normal border text-sm px-3  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                                                                  />
										</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="flex flex-col  my-3 px-4 py-12 bg-white">
						<div className="flex flex-col md:flex-row items-center w-full gap-4">
							
							<label htmlFor="vendor" className=" text-base w-full md:w-[20%]">
								Reference Number:
							</label>
							<div className="w-full">
							<input
								type="text"
								placeholder="Enter Invoice Reference Number"
								id="vendor"
								value={referenceNumber}
								onChange={onReferenceNumberChanged}
								className="mt-1 justify-end p-2 w-full  shadow-none  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none border border-gray-700"
							/>
									{errorMessage && (
    <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
  )}
		
							</div>
						
						
						</div>
				
						{/* <div className="flex items-center mt-3">
							
							<label htmlFor="vendor" className=" text-base flex-grow">
								Due Date:
							</label>
							<input
            type="date"
            min={new Date().toISOString().split('T')[0]}
            placeholder="Due Date"
            id="vendor"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="mt-1 justify-end p-2 w-4/5 shadow-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none border border-gray-700"
        />

<div className="flex items-center mt-3">
                  <label htmlFor="vendor" className="text-base flex-grow">
                    Due Date:
                  </label>
                  <DatePicker
                    oneTap
                    format='dd-MM-yyyy'
                    style={{ width: 775, height: 50 }}
                    onChange={(e) => setDueDate(e)}
                    defaultValue={dueDate}
                  />
                </div>
						</div> */}
						 {/* <div className="flex items-center mt-3">
                  <label htmlFor="vendor" className="text-base flex-grow">
                    Due Date:
                  </label>
                  <DatePicker
                    oneTap
                    format='dd-MM-yyyy'
                    style={{  height: 50 }}
                    onChange={(e) => setDueDate(e)}
                    defaultValue={dueDate}
					className="w-4/5"
                  />
                </div> */}
						<button
    disabled={
        !referenceNumberNull || 
        isAnyDiscountInvalid || isAnyTaxInvalid || errorMessage
    }
    onClick={handleNextPage}
    className={`md:self-end w-full px-4 mt-2 font-medium text-sm md:w-[10rem] py-2 border rounded-md ${
        (!referenceNumberNull || isAnyDiscountInvalid || isAnyTaxInvalid||errorMessage)
            ? "bg-gray-400 text-white cursor-not-allowed"
            : "bg-blue-500 text-white cursor-pointer"
    }`}
>
    Preview Invoice
</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateInvoiceStep4;
// const [selectedRows, setSelectedRows] = useState([]);
// const [currentItemsPage, setCurrentItemsPage] = useState(1);
// const [itemsPerPage, setItemsPerPage] = useState(5);

// const totalItems = selectedGRN;
// console.log(totalItems.length,'totalitems')
// const totalItemslength=totalItems.length
// const totalTablePages = Math.ceil( totalItemslength/ itemsPerPage);
// const indexOfLastItem = Math.min(currentItemsPage * itemsPerPage, totalItems);
// const indexOfFirstItem = (currentItemsPage - 1) * itemsPerPage;

// const currentItems = selectedGRN.slice(indexOfFirstItem, indexOfLastItem)

// const firstItemNumber = indexOfFirstItem + 1;
// const lastItemNumber = indexOfLastItem;