import { useLocation, Link,useNavigate } from 'react-router-dom';
import { generatePagination } from "../utils/generatePagination";
import { clsx } from "clsx";
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

export default function Pagination({totalPages, entriesPerPage, totalEntries}) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('page')) || 1;
  const pageSize = Number(params.get("page_size")) || entriesPerPage
  const startIndex = (currentPage - 1) * entriesPerPage + 1;
  const endIndex = Math.min(startIndex + entriesPerPage - 1, totalEntries);

  const createPageURL = (pageNumber) => {
    params.set('page', pageNumber.toString());
    return `${location.pathname}?${params.toString()}`;
  };
  const handleEntriesPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    params.set("page_size", newPageSize.toString());
    params.set("page", "1"); // Reset to page 1 when page size changes
    navigate(`${location.pathname}?${params.toString()}`);
 };
  const allPages = generatePagination(currentPage, totalPages);
  return (
    <div className="flex flex-col items-center justify-center sm:justify-between border-t border-gray-200 bg-white-50 px-4 py-3 sm:px-6">
    <div className="flex flex-col gap-2 md:gap-0 md:flex-row md:items-center justify-between w-full">
      <p className="text-sm text-gray-700 hidden sm:block">
        Showing <span className="font-sm">{startIndex}</span> to{" "}
        <span className="font-sm">{endIndex}</span> of{" "}
        <span className="font-sm">{totalEntries}</span> entries
      </p>
      <div className="flex items-center">
        <label
          htmlFor="entriesPerPage"
          className="mr-2 text-sm text-gray-700"
        >
          Entries per page:
        </label>
        <select
          id="entriesPerPage"
          value={pageSize}
          onChange={handleEntriesPerPageChange}
          className="border-gray-300 rounded-md text-sm"
        >
          {/* <option value={3}>3</option> */}
          <option value={3}>3</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={250}>250</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
        </select>
      </div>
      <div className="inline-flex">
        <PaginationArrow
          direction="left"
          to={createPageURL(currentPage - 1)}
          isDisabled={currentPage <= 1}
        />

        <div className="flex -space-x-px">
          {allPages.map((page, index) => {
            let position

            if (index === 0) position = "first";
            if (index === allPages.length - 1) position = "last";
            if (allPages.length === 1) position = "single";
            if (page === "...") position = "middle";

            return (
              <PaginationNumber
                key={index}
                to={createPageURL(page)}
                page={page}
                position={position}
                isActive={currentPage === page}
              />
            );
          })}
        </div>

        <PaginationArrow
          direction="right"
          to={createPageURL(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
        />
      </div>
    </div>
  </div>
  );
}
function PaginationArrow({
  to,
  direction,
  isDisabled}) {
  const className = clsx(
    'flex h-10 w-10 items-center justify-center rounded-md border',
    {
      'pointer-events-none text-gray-300': isDisabled,
      'hover:bg-gray-100': !isDisabled,
      'mr-2 md:mr-4': direction === 'left',
      'ml-2 md:ml-4': direction === 'right',
    },
  );

  const icon =
    direction === 'left' ? (
      <ArrowLeftIcon className="w-4" />
    ) : (
      <ArrowRightIcon className="w-4" />
    );

  return isDisabled ? (
    <div className={className}>{icon}</div>
  ) : (
    <Link className={className} to={to}>
      {icon}
    </Link>
  );
}
function PaginationNumber({
  page,
  to,
  isActive,
  position,
}) {
  const className = clsx(
    'flex h-10 w-10 items-center justify-center text-sm border',
    {
      'rounded-l-md': position === 'first' || position === 'single',
      'rounded-r-md': position === 'last' || position === 'single',
      'z-10 bg-blue-600  text-white': isActive,
      'hover:bg-gray-100': !isActive && position !== 'middle',
      'text-gray-300': position === 'middle',
    },
  );

  return isActive || position === 'middle' ? (
    <div className={className}>{page}</div>
  ) : (
    <Link to={to} className={className}>
      {page}
    </Link>
  );
}