import { useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import Error from '../../components/Error';
import Delivery from '../../assets/images/contract.jpeg'
import FoodConceptLogo from '../../assets/images/FoodConcepts_Logo-1 1.png'

const SignIn = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const msalConfig = {
        auth: {
            clientId: '47019b2a-7830-4234-9dcd-92ddbdf94d37',
            authority: 'https://login.microsoftonline.com/bb98776a-bc2d-48a3-afdb-73eccd45c5a2',
            redirectUri: 'https://vimp.foodconceptsplc.com/approval',
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage', 
            storeAuthStateInCookie: false,
        },
    };
    const handleLogin=() => {
        // Prevent useEffect from running if there's an error
        if (error) return;

        const msalInstance = new PublicClientApplication(msalConfig);
        const loginRequest = {
            scopes: ['473db494-352b-43ba-b289-70fb5888b3cf/read']
        };

        msalInstance.initialize()
            .then(() => {
                const currentAccounts = msalInstance.getAllAccounts();

                if (!currentAccounts || currentAccounts.length < 1) {
                    msalInstance.loginPopup(loginRequest)
                        .then((data) => {
                            console.log(data);
                            const currentAccounts = msalInstance.getAllAccounts();
                            const username = currentAccounts[0].username;
                            console.log(data, 'data');
                            const decoded = jwtDecode(data.accessToken);
                            const roles = decoded.roles;
                            console.log(roles, 'roles');

                            // Handle login logic
                            if (!roles || roles.length === 0) {
                                setError(true);
                                setShowModal(true)
                                setErrorMessage('You are not authorized! Kindly contact the admin to proceed.');
                            } else {
                                localStorage.setItem("account", JSON.stringify(data.account));
                                localStorage.setItem('roles', JSON.stringify(roles));
                                localStorage.setItem("accessToken", data.accessToken);
                                localStorage.setItem("idToken", data.account.idToken);
                                localStorage.setItem("name", data.account.name);
                                localStorage.setItem("username", data.account.username);
                                localStorage.setItem('isLoggedin', true);
                                localStorage.setItem('msalConfig', JSON.stringify(msalConfig));
                                toast.success('Login successful');
                                navigate('/approval/home');
                            }
                        })
                        .catch(error => {
                            console.error("Login Error: " + error);
                        });
                } else if (currentAccounts.length > 1) {
                    // Add your account choosing logic here
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    const username = currentAccounts[0].username;
                    localStorage.setItem("username", username);
                    navigate('/approval/home');
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }// Added 'error' as a dependenc

    return (
       <div className='h-screen'>
        <div className='bg-white h-16 flex items-center w-full'>
            <div className='flex items-center py-4 px-8'>
           <img src={FoodConceptLogo}  alt="" className='h-16 pr-2' />
            <h1 className="text-lg text-center pl-2 border-l border-gray-400 font-medium text-blue-900 hidden md:flex">
     Invoice Management Portal
            </h1> 
            </div>
        </div>
         <div className="relative h-full bg-cover bg-center" style={{ backgroundImage: `url(${Delivery})` }}>
        {/* <div className="relative h-screen bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${Delivery})` }}> */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
           
            <div className="flex flex-col w-[80%] md:w-[40%] text-center justify-center items-center">
           
            <p className="text-4xl font-bold text-white mb-3">Invoice Approval Portal</p>
            <p className="text-sm font-normal text-white mb-3">Enhancing efficiency and transparency in managing invoice approvals </p>
       
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white font-semibold mt-8 py-3 px-6 rounded-lg "
              type="b"
              aria-disabled={false}
              onClick={handleLogin}
            >
              Get Started
            </button>
            </div>
           
           
          
            </div>
            {error && <Error message={errorMessage} show={showModal} handleShow={() => setShowModal(false)} />}
        </div>
       </div>
    );
};

export default SignIn;