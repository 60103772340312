import React from 'react';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0  bg-black bg-opacity-50   flex items-center justify-center z-50">
      
        <div className="mb-4 w-[90%] max-w-md">{children}</div>
       
      
    </div>
  );
};

export default Modal;
