import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";
import Loader from "../components/Loader";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { NumericFormat } from "react-number-format";
import Error from "../components/Error";
import Pagination from "../components/Pagination";
import { useLocation } from "react-router-dom";
import Position from "rsuite/esm/internals/Overlay/Position";

const History = () => {

  const [filteredCreatedList, setFilteredCreatedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionExpired, setsessionExpired] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(1);
  const [count, setCount] = useState(1);
  //const navigate = useNavigate();
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0
  //     ? Math.max(0, (1 + page) * rowsPerPage - filteredCreatedList.length)
  //     : 0;

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  useEffect(() => {
    // Retrieve draft and submitted data from local storage
    // const storedDraftList = JSON.parse(localStorage.getItem('Drafted')) || [];
    // console.log(storedDraftList[0],'storeeddd')
    // const storedSubmittedList = JSON.parse(localStorage.getItem('Submitted')) || [];

    // Combine both lists
    // const combinedData = [...storedDraftList, ...storedSubmittedList];
    // setCombinedList(combinedData);

    // Set individual lists
    //
    // setDraftList(storedDraftList);
    // setSubmittedList(storedSubmittedList);
    // const fetchData = async () => {
    //   try {
    //     const token = localStorage.getItem("access"); // Assuming token is stored in localStorage
    //     setLoading(true);
    //     const response = await axios.get(
    //       `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/profile`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );

    //     // Handle the response data here
    //     console.log(response.data, "info");
    //     // setVendorDetails(response.data.data);
    //     localStorage.setItem(
    //       "vendorDetails",
    //       JSON.stringify(response.data.data)
    //     );
    //   } catch (error) {
    //     console.log(error, "PO fetching error.");
    //     const errorMessage =
    //       error?.response?.data?.detail || "Error Fetching PO Items"
    //         ? error?.response?.data?.detail
    //         : "Error Fetching PO";

    //     if (error?.response?.status === 401) {
    //       // Call handleLogout in case of 401 error
    //       // handleLogout(msalConfig, userName, navigate);
    //       setsessionExpired(true);
    //     } else {
    //       setError(errorMessage);
    //     }
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // fetchData();
    getHistory(page, size);
  }, []);

  const animatedComponents = makeAnimated();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // console.log(historyData[0],'historyyyyy')
  // Render action buttons based on item status

  // Filter the combined list based on search query

  const token = localStorage.getItem("access");

  console.log(historyData, "ggggg");
  // Function to filter historyData based on searchQuery

  const getHistory = async (page, size) => {
    setLoading(true);
    // setShowVendorSearchButton(false); // Hide the search button
    // setLoading(true); // Show loading indicator

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/invoices?page=${page}&size=${size}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data, "I am the res");
      if (response.data.data && response.data.data.length === 0) {
        toast.error("No record found");
        setHistoryData([]); // Clear previous data if needed
      } else {
        console.log(response, "I am the res");
    setHistoryData(response.data.data.results);
    setCount(response.data.data.count)
    const totalPages=Math.ceil(response.data.data.count/size)
    setTotal(totalPages)
      }
      // handleNextPage()
    } catch (error) {
      console.error(
        "Error fetching search results:"
        // error.resonse
      );
      // Swal.fire({
      //   title: "Not Found",
      //   text: error.response.data.message,
      //   icon: "error",
      // });
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  function calculateProductTotal(data) {
    const lineItems = data.invoice_line_items || [];
    console.log(data, "dataaa");
    // Sum the quantity received for each line item
    const totalQuantityReceived = lineItems.reduce((total, lineItem) => {
      const quantityReceived = parseFloat(lineItem.gross_total);
      return total + quantityReceived;
    }, 0);

    return totalQuantityReceived;
  }
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  function handlexit() {
    setFilteredCreatedList(filteredData);
  }
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get("page");
    const pageSizeParam = params.get("page_size");

    const newPage = pageParam ? Number(pageParam) : 1;
    const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

    setPage(newPage);
    setSize(newPageSize);

    getHistory(newPage, newPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  /// Function to filter historyData based on searchQuery
  const filteredData = historyData?.filter((item) => {
    console.log(item,'items')
    const { external_document_id, grn, gross_total } = item;
    const searchValue = searchQuery.toLowerCase();

    const matchesExternalDocumentId = external_document_id?.toString()
      .toLowerCase()
      .includes(searchValue);
    const matchesGross_Amount = gross_total
      .toString()
      .toLowerCase()
      .includes(searchValue);
    const matchesPoId = grn.purchase_order.po_id
      .toString()
      .toLowerCase()
      .includes(searchValue);
    const matchesGRN = grn.grn_number
      .toString()
      .toLowerCase()
      .includes(searchValue);

    return matchesExternalDocumentId || matchesPoId || matchesGross_Amount || matchesGRN;
  });

  const [statusFilter, setStatusFilter] = useState("All");
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      // width: "140px",
      width: "140px",
      borderColor: "#eaeaea",
      margiTop: "-1px",
      Position:'relative',
      backgroundColor: state.isDisabled ? "grey" : "white",
      
    }),
  };
  const Select2Style = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      width: "180px",
      borderColor: "#eaeaea",
      margiTop: "-1px",
      backgroundColor: state.isDisabled ? "grey" : "white",
    }),
  };
  // Define a function to filter the table data based on the selected status
//   function filterTableData(statusFilter) {
//     if (statusFilter === 'All') {
//         return filteredData; // Return all items if 'All' is selected
//     } else {
//         return filteredData.filter(item => {
//             if (statusFilter === 'Approved') {
//                 return item.workflow.completed && item.workflow.approved;
//             } else if (statusFilter === 'Declined') {
//                 return item.workflow.completed && !item.workflow.approved;
//             } else if (statusFilter === 'Pending') {
//                 return !item.workflow.completed;
//             }
//             return false;
//         });
//     }
// }
function filterTableData(statusFilter) {
  if (statusFilter === 'All' || statusFilter === 'All Delivery Status') {
      return filteredData; // Return all items if 'All' is selected
  } else if (['Approved', 'Declined', 'Pending'].includes(statusFilter)) {
      // Handle workflow filtering
      return filteredData.filter(item => {
          if (statusFilter === 'Approved') {
              return item.workflow.completed && item.workflow.approved;
          } else if (statusFilter === 'Declined') {
              return item.workflow.completed && !item.workflow.approved;
          } else if (statusFilter === 'Pending') {
              return !item.workflow.completed;
          }
          return false;
      });
  } else if (['Completely Delivered', 'Partially Delivered'].includes(statusFilter)) {
      // Handle delivery status filtering
      const deliveryStatus = statusFilter === 'Completely Delivered';
      return filteredData.filter(
          (item) => item.grn.purchase_order.delivery_completed === deliveryStatus
      );
  }
  return filteredData; // Default return if no match
}


  // Update state with filtered data based on search query
  useEffect(() => {
    setFilteredCreatedList(filteredData);
  }, [searchQuery, historyData]);

  // Function to filter filteredCreatedList based on selected date

  function filterByDate(selectedDate) {
    if (!selectedDate) {
      setFilteredCreatedList(filteredData);
    } else {
      const formattedSelectedDate = formatDate(selectedDate);

      // Filter the data based on the selected date
      const filtered = filteredData.filter((item) => {
        const itemDate = formatDate(new Date(item.purchase_order.date));
        return formattedSelectedDate === itemDate;
      });
      setFilteredCreatedList(filtered);
    }

    // Update state with the filtered data
  }

  // Function to format date
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // function filterByDateRange(startDate, endDate) {
  //   if (!startDate || !endDate) {
  //     setFilteredCreatedList(filteredData);
  //   } else {
  //     const formattedStartDate = formatDate(startDate);
  //     const formattedEndDate = formatDate(endDate);

  //     // Filter the data based on the selected date range
  //     const filtered = filteredData.filter(item => {
  //       const itemDate = formatDate(new Date(item.created));
  //       return itemDate >= formattedStartDate && itemDate <= formattedEndDate;
  //     });
  //     setFilteredCreatedList(filtered);
  //   }
  // }
  const filterByDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) {
      setFilteredCreatedList(filteredData);
    } else {
      const formattedStartDate = new Date(startDate).setHours(0, 0, 0, 0);
      const formattedEndDate = new Date(endDate).setHours(23, 59, 59, 999);

      // Filter the data based on the selected date range
      const filtered = filteredData.filter((item) => {
        const itemDate = new Date(item.due_date).setHours(0, 0, 0, 0);
        return itemDate >= formattedStartDate && itemDate <= formattedEndDate;
      });

      setFilteredCreatedList(filtered);
    }
  };
console.log(filteredCreatedList,'new')
  const handleShowInvoice = (data) => {
    localStorage.setItem("PostResponse", JSON.stringify(data));
    navigate("/invoice", { state: { history: true } });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  // function filterTableDatabyStatus(statusFilter) {
  //   if (statusFilter === "All") {
  //     setFilteredCreatedList(filteredData); // Return all items if 'All' is selected
  //   } else {
  //     // Determine the boolean value for filtering
  //     const status = statusFilter === "Completely Delivered";
  //     // Filter items based on the selected status
  //     return filteredData.filter(
  //       (item) => item.grn.purchase_order.delivery_completed === status
  //     );
  //   }
  // }
  return (
    <>
      {!sessionExpired ? (
        <div className="">
          <Header />
          <div className="flex main-body">
            <div className="sidebar hidden md:block">
              <Sidebar />
            </div>
            <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3">
              <PageTitle pagetitle="Invoice History" className="mt-3" />
              {loading && <Loader />}
              {error && <Error message={error} />}
              <section class="bg-gray-50  sm:p-5">
                <div class="mx-auto w-full ">
                  <div class="bg-white flex flex-col relative   sm:rounded-lg overflow-hidden h-screen">
                    <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-2">
                      <div class="w-full md:w-1/3">
                        <form class="flex items-center">
                          <label for="simple-search" class="sr-only">
                            Search
                          </label>
                          <div class="relative w-full">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg
                                aria-hidden="true"
                                class="w-5 h-5 text-gray-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <input
                              type="text"
                              id="simple-search"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full pl-8 py-2"
                              placeholder="Search by PO number, Invoice  reference number and total amount"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              required=""
                            />
                          </div>
                        </form>
                      </div>
                      <div className="flex gap-3 ">
                        <DateRangePicker
                          format="dd-MM-yyyy"
                          placeholder="Filter by Date Range"
                          style={{ width: 200, height: 50 }}
                          ranges={[]}
                          placement="bottomEnd"
                          preventOverflow
                          onChange={(range) => {
                            if (range) {
                              const [startDate, endDate] = range;
                              console.log(range, "range");
                              filterByDateRange(startDate, endDate); // Filter data based on selected date range
                            } else {
                              handlexit();
                              console.log(filteredData, "filtereddat");
                            }
                          }}
                          onClean={handlexit}
                        />

{/* <Select
  closeMenuOnSelect={true}
  components={animatedComponents}
  styles={colourStyles}
  placeholder="Filter by status" // Add a placeholder
  options={[
   
    { value: 'All', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Declined', label: 'Declined' }
  ]}
  value={statusFilter ? { value: statusFilter, label: statusFilter } : null} // Show the selected value or nothing
  onChange={(selectedOption) => {
    setStatusFilter(selectedOption.value);
    const updatedFilteredList = filterTableData(selectedOption.value); // Filter data based on selected value
    setFilteredCreatedList(updatedFilteredList); // Update state with the filtered data
  }}
/>
<Select
  closeMenuOnSelect={true}
  components={animatedComponents}
  styles={colourStyles}
  options={[
    { value: 'All Delivery Status', label: 'All Delivery Status' },
    { value: 'Partially Delivered', label: 'Partially Delivered' },
    { value: 'Completely Delivered', label: 'Completely Delivered' } // Corrected typo in the value
  ]}
  value={{ value: statusFilter, label: statusFilter }} // Set value to an object with value and label properties
  onChange={(selectedOption) => {
    setStatusFilter(selectedOption.value);
    const filteredData = filterTableDatabyStatus(selectedOption.value); // Call filterTableData with the selected value
    // Handle filtered data as needed, e.g., update state with filtered data
    setFilteredCreatedList(filteredData);
  }}
/> */}
<Select
  closeMenuOnSelect={true}
  components={animatedComponents}
  placement='bottomStart'
  styles={{
    ...colourStyles, // Keep your existing styles
    control: (provided) => ({
      ...provided,
      height: '36px',      // Set the height to 40px
      minHeight: '36px',   // Ensure the minimum height is also 30px
      padding: '0', 
      width:'200px'       // Adjust padding for height control
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',    // Adjust padding inside the control
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',         // Remove margin to fit the height
      padding: '0',        // Remove padding for input text
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '400px',  // Limit the height for overflow
      overflowY: 'auto', 
      top:'33px', 
      zIndex:30 // Enable scrolling
    })
  }}
  placeholder='Filter by status'
  options={[
    { value: 'All', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Partially Delivered', label: 'Partially Delivered' },
    { value: 'Completely Delivered', label: 'Completely Delivered' }
  ]}
  value={statusFilter ? { value: statusFilter, label: statusFilter } : null} // Show the selected value or nothing
  onChange={(selectedOption) => {
    setStatusFilter(selectedOption ? selectedOption.value : null); // Clear value if no selection
    const updatedFilteredList = filterTableData(selectedOption ? selectedOption.value : 'All'); // Call the combined filter function
    setFilteredCreatedList(updatedFilteredList); // Update state with the filtered data
  }}
/>




                      </div>
                    </div>

                    <div class="overflow-x-auto md:w-full w-[90%] mx-auto">
                      <table className="w-full  min-w-sm">
                        <thead className="rounded-lg text-left bg-blue-800 text-sm font-normal">
                          <tr className="font-poppins text-19 font-semibold leading-29">
                            <th scope="col" class="px-4 py-3 text-white">
                              SN
                            </th>

                            <th scope="col" class="px-4 py-3 text-white">
                              PO No
                            </th>
                            <th scope="col" class="px-4 py-3 text-white">
                              GRN No
                            </th>
                            <th scope="col" class="px-4 py-3 text-white">
                              Reference No
                            </th>
                           
                            <th scope="col" class="px-4 py-3 text-white">
                              Date Created
                            </th>
                           
                           
                            <th scope="col" class="px-4 py-3 text-white">
                              Delivery Status
                            </th>
                            <th
                              scope="col"
                              class=" py-3 text-right
                                pr-4 text-white"
                            >
                              Amount
                            </th>
                           
                            <th scope="col" class="px-4 py-3 text-white">
                              Approval Status
                            </th>

                            {/* <th scope="col" class="px-4 py-3 text-white">
                                  Delivery Status
                                </th> */}
                            <th
                              scope="col"
                              className=" py-3 pr-3 text-white text-center"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody>
  {filteredCreatedList && Array.isArray(filteredCreatedList) ? (
    filteredCreatedList.length === 0 ? (
      <tr>
        <td colSpan="8" className="text-center py-4 text-red-600 font-semibold">
          No record found!
        </td>
      </tr>
    ) : (
      filteredCreatedList.map((row, index) => (
        <tr className="w-full border-b py-2 text-sm" key={index}>
          <td className="px-4 py-3">
            {page === 1 ? page + index : size * page - size + 1 + index}
          </td>
          <td className="px-4 py-3">
            {row.grn?.purchase_order?.po_id ? row?.grn?.purchase_order?.po_id : "-"}
          </td>
          <td className="px-4 py-3">
            {row.grn?.grn_number ? row?.grn?.grn_number : "-"}
          </td>
          <td className="px-4 py-3">
            {row?.external_document_id ? row?.external_document_id : "-"}
          </td>
          <td className="px-4 py-3">
            {format(row?.date_created, "dd-MM-yyyy")}
            {/* {row.created} */}
          </td>
          <td className="px-4 py-3">
            <span className={`rounded-3xl px-3 py-1 ${
              row?.grn.purchase_order.delivery_status_text === 'Completely Delivered'
                ? 'bg-green-100 text-green-700'
                : row.purchase_order.delivery_status_text === 'Not Delivered'
                ? 'bg-red-100 text-red-600'
                : 'bg-gray-100 text-gray-600'
            }`}>
              {row.grn.purchase_order.delivery_status_text === 'Partially Delivered' ? 'Partial' : 'Complete'}
            </span>
          </td>
          <td className="text-right pr-4 py-3">
            ₦
            <NumericFormat
              value={row.gross_total}
              displayType="text"
              thousandSeparator={true}
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
            />
          </td>
         
          <td className="px-4 py-3">
          <span
            className={`rounded-3xl px-3 py-1 ${
             row.workflow.completed
                ?row.workflow.approved
                  ? 'bg-green-100 text-green-700'
                  : 'bg-red-100 text-red-700'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            {row.workflow.completed
              ?row.workflow.approved
                ? 'Approved'
                : 'Declined'
              : 'Pending'}
          </span>
            {/* {row.created} */}
          </td>
          <td className="flex py-3  font-semibold text-center">
            <button
              onClick={() => handleShowInvoice(row)}
              className="border border-blue-700 bg-blue-700 items-center flex gap-1 rows-center justify-center py-1 hover:bg-blue-800 text-white px-2 rounded"
            >
              <RemoveRedEyeIcon sx={{ fontSize: 16 }} />
              <span className="text-xs">View</span>
            </button>
          </td>
        </tr>
      ))
    )
  ) : (
    <tr>
      <td colSpan="8" className="text-center py-4 text-red-600 font-semibold">
        No record found!
      </td>
    </tr>
  )}
</tbody>


                      </table>
                      <Pagination
totalPages=
{total}
entriesPerPage=
{size}
totalEntries=
{count}/>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <sessionExpired />
      )}
    </>
  );
};

export default History;