import React, { useEffect, useState } from 'react';
import Header from '../apprLyout/Topnav';
import Loader from '../../components/Loader';
import Sidenav from '../apprLyout/Sidenav';
import OtherPageTitle from '../../components/otherpagesTitle'; 
import { useNavigate } from 'react-router-dom';
import Invoice from '../../components/Invoice';
const ApprovedInvoice = () => {
  const receipt = JSON.parse(localStorage.getItem('receiptdata'));
  console.log(receipt,'receipt')
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


 
  const Colour =  '#027C94';
 
  const navigate = useNavigate();
  const handlePreviousPage = () => {
    const previousPath = localStorage.getItem('previousPath');
    if (previousPath) {
      navigate(previousPath);
    } else {
      navigate(-1); // Fallback to the browser's default back navigation if no previous path is saved
    }
  };
console.log(receipt,'invoice receipt')
  return (
    <div>
      <Header />
      <div className="flex main-body">
        <div className="sidebar hidden md:block">
          <Sidenav />
        </div>
        <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3 mt-[70px]">
          {loading ? (
            <Loader />
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <>
            
            <OtherPageTitle title='Invoice' action={handlePreviousPage} />
            <Invoice receipt={receipt}/>
           </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovedInvoice;
