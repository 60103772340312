import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import Loader from './Loader';
import Swal from 'sweetalert2';
import AuthSpinner from './Authspinner';
const PasswordResetModal = ({ isModalOpen, closeModal, token }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuggestions, setShowSuggestions] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false); // Toggle for current password
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle for new password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password

  // Password strength validation
  const validatePasswordStrength = (password) => {
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handlePasswordUpdate = async () => {
    // Check if new password matches confirm password
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
  
    // Check if new password is strong enough
    if (!validatePasswordStrength(newPassword)) {
      setErrorMessage('Weak Password!');
      setShowSuggestions(true);
      return;
    }
  
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    const payload = {
      old_password: currentPassword,
      new_password: newPassword,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/password-change`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        setSuccessMessage('Password updated successfully!');
        closeModal(); // Close the modal
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: data.message || 'Password updated successfully!',
        });
      } else {
        const errorData = await response.json();
        closeModal();
        console.log(errorData, 'errorData');
        setErrorMessage(errorData.detail || 'Failed to update password.');
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: errorData.detail || 'Failed to update password.',
        });
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
      console.log(error, 'error');
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.detail || 'Something went wrong. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setErrorMessage(''); // Clear error message on input change
    setShowSuggestions(false);
  };

  return (
    <div>
{
  loading ? (
    <Loader />
  ) : (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Reset Password</h2>

        {/* Reset Password Form */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Current Password
          </label>
          <div className="relative">
            <input
              type={showCurrentPassword ? "text" : "password"}
              value={currentPassword}
              onChange={handleInputChange(setCurrentPassword)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            />
            <button
              type="button"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              className="absolute inset-y-0 right-3 flex items-center text-gray-500"
            >
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            New Password
          </label>
          <div className="relative">
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={handleInputChange(setNewPassword)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            />
            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute inset-y-0 right-3 flex items-center text-gray-500"
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Confirm New Password
          </label>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleInputChange(setConfirmPassword)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute inset-y-0 right-3 flex items-center text-gray-500"
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {errorMessage && (
            <p className="text-red-600 mt-3">{errorMessage}</p>
          )}
          {showSuggestions && (
            <div className="mt-2 text-red-600 text-sm">
              <p>Suggestions for a strong password:</p>
              <ul className="list-disc ml-5">
                <li>At least 8 characters long</li>
                <li>Contains both uppercase and lowercase letters</li>
                <li>Includes at least one number</li>
                <li>Has at least one special character (e.g., !, @, #, $, %)</li>
              </ul>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="bg-gray-100 text-blue-600 py-2 px-4 rounded-md hover:bg-gray-200 mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handlePasswordUpdate}
            className={`bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 ${
              loading
                ? "d-flex align-items-center justify-content-center gap-1"
                : ""
            } ${errorMessage ? "cursor-not-allowed opacity-50" : ""}`}
            disabled={!!errorMessage || loading}
            style={{
              cursor: errorMessage || loading ? "not-allowed" : "pointer",
            }}
          >
            Update Password
          </button>
        </div>
      </div>
    </div>
  )
}

    </div>
  )
}
export default PasswordResetModal;
