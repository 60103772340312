import React, { useState, useEffect } from 'react';
import AuthenticationPages from '../components/AuthenticationPages';
import { Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthSpinner from '../components/Authspinner';
import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS, USER_LOGIN_REQUEST } from '../redux/constants/userConstants';
import TwoFactorPage from './TwoFactorPage';
import { setNavigationState } from '../redux/actions/userActions';
import axios from 'axios';
import { ReactComponent as Envelope } from '../assets/images/Group.svg';
import { ReactComponent as EyeIcon } from '../assets/images/eye.svg';
import { ReactComponent as EyeIconBlock } from '../assets/images/invisible.svg';

const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/v1/vendor/authenticate`,
      { username, password }
    );

    if (!response.data) {
      throw new Error('Response data is undefined');
    }

    const { data } = response;
    localStorage.setItem('success', data.status);
    localStorage.setItem('username', username);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

const SignIn = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const refresh=localStorage.getItem('refresh')||''
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo, error } = userLogin;
  const successStatus = localStorage.getItem('success');
  // Check if success is stored in localStorage
  useEffect(() => {
   
    if (successStatus === 'success') {
      setIsVerificationRequired(true);  // Show TwoFactorPage
    } else {
      setIsVerificationRequired(false); // Show login form
      if (refresh===''){
        setErrorMessage('')
      }

    }
  }, [successStatus]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  const handleSignUp = () => {
    dispatch(setNavigationState({ actionType: 'signup' }));
    navigate('/verification');
  };
  
  const handleForgotPassword = () => {
    dispatch(setNavigationState({ actionType: 'forgotPassword' }));
  }; 
  return (
    <div className='w-full'>
    {
      // Check if it's the first sign-in, if true, show the profile settings modal
   
        // If not the first sign-in, show the regular sign-in form
        isVerificationRequired ?
          <TwoFactorPage password={password} username={username} /> :
          <AuthenticationPages FormTitle="Sign In" formtext="Enter your userID and password to login.">
            <Form onSubmit={submitHandler}>
              {error &&errorMessage !=='' &&
                <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3  rounded relative" role="alert">
                  <span class="block sm:inline">{error}</span>
                </div>
              }
              <Form.Group className='position-relative w-full'>
                <Form.Label className='text-[#393838] text-sm font-semibold mt-2' >
                  UserID
                </Form.Label>
                <input id="username" className='d-none' type="text" name="fakeusernameremembered" />
                <input id="password" className='d-none' type="password" name="fakepasswordremembered" />
                <Form.Control
                  type='text'
                  name='randomNameEmail'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  autoComplete="nope"
                  placeholder='Vendor ID'
                  className='appearance-none border-gray-900 rounded w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm'
                />
                <div className="position-absolute" style={{ right: "10px", top: "73%", transform: "translateY(-50%)" }}>
                  <Envelope />
                </div>
              </Form.Group>
              <Form.Label className='text-gray-[#393838] text-sm font-semibold mt-3' >
                Password
              </Form.Label>

              <InputGroup>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ border: '1px solid #727272', height: '40px' }}
                  placeholder='Password'
                  className='appearance-none border-gray-900  w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-sm'
                />
                <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer', border: '1px solid #727272', height: '40px' }}>
                  {!showPassword ? <EyeIcon /> : <EyeIconBlock />}
                </InputGroup.Text>
              </InputGroup>
              <div className='d-flex justify-content-between my-2' style={{ width: '100%' }}>
                <Form.Check
                  label='Remember me'
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className='custom-checkbox text-sm rounded-none leading-[1.5] text-[#393838]  '
                />

              </div>
              <Button
                className={`authentication-button text-lg  ${loading && 'd-flex align-items-center justify-content-center gap-1'}`}
                type='submit'
              >
                {loading && <AuthSpinner />} <span>{loading ? '' : 'Sign In'}</span>


              </Button>{' '}
              <div className="flex text-base gap-2 leading-[1.53] tracking-normal text-left my-3">
                <span>Forgot password?</span>
             <Link 
  className='light-blue-text' 
  to='/verification'
  onClick={handleForgotPassword} 
  state={{
    
    state: { status: 'forgotPassword' }
  }}
>
  click here
</Link>


              </div>
              <Button className='account-creation-button text-sm mx-auto ' onClick={handleSignUp}>
                <span>Don't have an account?</span> Sign Up
          </Button>{' '}

            </Form>
          </AuthenticationPages>}
  </div>
  );
};

export default SignIn;
