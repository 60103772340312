import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { convertDateFormat } from './GRN';
import Logo from '../assets/images/foodclogo.png'
import { NumericFormat } from 'react-number-format';
  
const GrnApproval = () => {
  const navigate = useNavigate();  
  const receipt= JSON.parse(localStorage.getItem('receiptdata')) || [];
  const chickenConversion = receipt.invoice_line_items.some(item => 
    item.grn_line_item?.metadata?.total_weight_received !== undefined
);
const calculateTotalTaxAmount = () => {
    const totalTax = receipt.invoice_line_items.reduce((sum, item) => {
      return sum + (item.grn_line_item.tax_value || 0);
    }, 0);
  
    return totalTax;
  };
  function calculateProductTotal() {
    const lineItems = receipt.invoice_line_items || [];
    
    // Calculate the total gross value received
    const grossTotal = lineItems.reduce((total, lineItem) => {
      const grossValueReceived = parseFloat(lineItem.grn_line_item.gross_value_received);
      return total + grossValueReceived;
    }, 0);
  
    return grossTotal;
  }
  const check= receipt.invoice_line_items.some(item=>item?.grn_line_item?.extra_fields)
// const mailStatus=object.send_email?object.send_email:false
  useEffect(() => {
    const printAndNavigate = () => {
      window.print();
      // Delay navigation to ensure print dialog interaction
      setTimeout(() => {
        navigate('/approval/grn');
      }, 500); // Adjust the delay if needed
    };

    printAndNavigate();
  }, [navigate]);

  return (
    <div className='bg-white  w-full  box-shadow' >
              <div id='pdf' className='bg-white w-full'>
            <div className=" items-center flex  bg-white ">
                <div className="flex flex-col items-center">
                    <img className=' h-24 md:h-32' src={Logo} alt="" />
                    <p className='font-semibold text-lg md:text-2xl -mt-8 text-[#2957A4]'>Food Concepts</p>
                </div>
                <div className="flex flex-col items-center self-center justify-center">
                    <p className='font-semibold text-xl text-center m:text-3xl  text-blue-800 md:ml-16 my-2 md:my-4'>Food Concepts (Chicken Republic)</p>
                    <p className='text-[#474646] font-normal text-center text-base md:ml-16'><span className='underline  md:pb-3'>2, Ilupeju Bye Pass, Ilupeju, Lagos, Nigeria </span>Tel: +234 (0) 809 016 5972 | +234 (0) 809 016 5973</p>
                </div>
      
            </div>
            <div className="flex justify-between items-center text-[#545454] text-base py-4">
      <ul className="m-0 p-0 justify-end text-sm">
  <li className='flex gap-6 text-justify'>
    <span className='flex-grow'>Received from:</span>
    <span className='text-justify'>Supplier Name</span>
  </li>
  <li className='flex gap-6 '>
    <span className='flex'>Store Name:</span>
    <span className=''>Store Name</span>
    {/* <span className='text-justify'>{deliveryLocation}</span> */}
    
  </li>
</ul>
{/**
 * {
    "message": "GRN Created",
    "data": {
        "id": 48,
        "purchase_order": 4,
        "store": 1,
        "grn_number": 255166,
        "received_date": "2024-03-14",
        "line_items": [
            {
                "id": 31,
                "grn": 48,
                "purchase_order_line_item": 14,
                "quantity_received": "40.000"
            }
        ]
    },
    "status": "success"
}
 */}
        <div className='flex flex-col'>
        <div className="md:text-right text-[#5B5B5B] font-normal text-center text-xl my-2">Goods Reciept Note</div>
        <div className="border border-gray-200 font-normal flex text-sm flex-col">
          <div className="border-b border-gray-200  flex  p-2 m-0">
            <span>Date</span>
            <span className=" text-right flex-grow ">28th August, 2024</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>Purchase Order Number</span>
            <span className="text-right flex-grow ">321</span>
          </div>
          <div className="border-b border-gray-200 flex gap-36  p-2 m-0">
            <span>GRN Number</span>
            <span className="text-right flex-grow ">3211</span>
          </div>
       
          
        </div>
        </div>
     
      </div>

      <section class="bg-gray-50 ">
    <div class="mx-auto ">
      
        <div class="bg-white  relative  overflow-hidden">
            
            <div class="overflow-x-auto">
            <div className="bg-white  md:w-full w-full  mx-auto ">
<table className="w-full mt-4 border-gray-200 ">
      <thead
        className=" text-white text-sm w-full bg-blue-700"
      
      >
        <tr>
          <td className="text-sm font-medium py-2  px-2">SN</td>
          <td className="text-sm font-medium px-2">Description</td>
          <td className="text-sm font-medium px-2">Product Code</td>
          <td className="text-sm font-medium  px-2">UoM</td>
          <td className="text-sm font-medium  text-right">Qty Received</td>
          
          <td className="text-sm font-medium text-right px-2">Qty Outstanding</td>
          {chickenConversion && <td className="  py-3 font-normal px-2 text-right " >Pieces Received</td>}
          
          <td className="text-sm font-medium text-right py-2 px-2">
            Unit Price
          </td>
      
           <td className="text-sm font-medium text-right  px-2">
            Tax
          </td>
          <td className="text-sm font-medium text-right ">Discount</td>
          <td className="text-sm font-medium text-right  px-2">
            Total Price
          </td>
      
            
       
        </tr>
      </thead>
      <tbody>
        {receipt.invoice_line_items.map((item, index) => (
          <tr key={index} className="border-b dark:border-gray-700 text-sm">
            <td className="text-sm pl-2 py-3 px-2">{index + 1}</td>
            <td className="pl-8 text-sm px-2">{item.grn_line_item.product_name|| 'Product Name'}</td>
            <td className="pl-8 text-sm px-2">{item.grn_line_item.product_code||'Product Code'}</td>
            <td className=" text-sm px-2">{item.grn_line_item.unit_of_measurement||'Unit of Measurement'}</td>
            <td className=" text-sm  text-right px-2">
              <NumericFormat value={item.quantity ? item.grn_line_item.quantity_received : item.grn_line_item.quantity_received} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
            <td className=" text-sm  text-right px-2">
              Outsanding Quantity
            </td>
            {chickenConversion &&  (item.grn_line_item.metadata.total_pieces_received? <td className="text-right px-2 py-3"><NumericFormat value={item.grn_line_item.metadata.total_pieces_received}displayType={'text'} thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                                
                                </td>: <td className='text-right pr-6  py-3'>NA</td> )}
            <td className="text-sm  text-right px-2">
              {/* ₦<NumericFormat value={item.grn_line_item.unit_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /> */}
              Unit Price
            </td>
          
            
              <td className="text-sm px-2 text-right ">
              ₦<NumericFormat value={item.grn_line_item.tax_value} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
              </td> 
            <td className="text-sm px-2 text-right">{item.discount ? item.discount : 0.00}</td>
            <td className="text-sm px-2 text-right ">
              ₦<NumericFormat value={item.grn_line_item.net_value_received
} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
            </td>
          </tr>
        ))}
                                                           <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
     
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      <td></td>
      <td></td>
      <td className="   py-3 " >Sub Total</td>
      <td className="text-right pr-2   py-3 ">  <NumericFormat value={calculateProductTotal()} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
    <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
     
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      <td></td>
      <td></td>
      <td className="pr-4  py-3 " >Total Tax </td>
      <td className="text-right pr-2  py-3 ">  <NumericFormat value={calculateTotalTaxAmount()} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
     
                                                   <tr className=' border-b border-gray-300'>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      
      {chickenConversion && <td></td>}
      {check &&<td></td>}
      <td></td>
      <td></td>
      <td className="  pr-4 py-3 " >Total </td>
      <td className="text-right pr-2  py-3 ">  <NumericFormat value={(calculateProductTotal()+calculateTotalTaxAmount())} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale /></td>
    </tr>
      </tbody>
    </table>
      

</div>
            </div>
           
        </div>
    </div>
    </section>
                        </div>    
                                                  
      </div>
    
  );
};

export default GrnApproval;
