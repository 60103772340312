import React,{useEffect,useRef} from 'react'
import { NumericFormat } from 'react-number-format'
import {useNavigate} from 'react-router-dom'
import {format} from 'date-fns'
import formatText from '../components/formatText'
const Pdf = () => {
  const vendordetails=JSON.parse(localStorage.getItem('vendorDetails')) || []
  const SelectedItems = JSON.parse(localStorage.getItem('PostResponse')) || { filteredData: [] };
  const discountincluded=SelectedItems?.invoice_line_items?.some(item=>item.discountable)
  const vendorname = vendordetails?.user?.vendor_name
    const navigate=useNavigate()  
    useEffect(() => {
        window.print();
        navigate('/invoice')
      }, []);
      let rowIndex = 0;
      const taxincluded = SelectedItems?.invoice_line_items?.some(item => item.tax_amount);
const Colour = vendordetails?.vendor_settings?.invoice_color ?? '#027C94';
const Signature=vendordetails.signature?vendordetails.signature:'Vendor name'
// const Colour = '#027C94';
const userInfo=JSON.parse(localStorage.getItem('userInfo')) || []
const imageRef = useRef(null);
const { completed, approved, signatures } = SelectedItems.workflow;

// Determine the status, comment, and color
let status = '';
let comment = '';
let statusColor = '';

if (completed && approved) {
  status = 'Approved';
  comment = signatures.find(sig => sig.role === 'line_manager')?.comment || '';
  statusColor = 'text-green-600'; // Green for Approved
} else if (completed && !approved) {
  status = 'Declined';
  // comment = signatures.find(sig => sig.role === 'internal_control')?.comment || '';
  statusColor = 'text-red-600'; // Red for Declined
} else {
  status = 'Pending';
  statusColor = 'text-yellow-600'; // Yellow for Pending
}
  return (
  
    <div className="bg-white flex flex-col py-4  w-full" id="pdf">
   
      <div className="flex flex-row  md:justify-between px-4">
        <div>
          <img
            ref={imageRef}
            src={userInfo?.vendor_settings?.logo}
            alt="vendor logo"
            className="w-32 h-32 my-3 object-contain"
          />
          <p
            className="text-xl py-1 font-bold"
            style={{ color: Colour }}
          >
            {/* {userInfo?.vendor_settings?.logo} */}
            {vendorname}
          </p>
        <p className="text-base">Telephone: {vendordetails?.phone} </p>
          <p className="text-base">Email: {userInfo?.email} </p>
          <div
            className="w-full text-white text-base font-semibold bg-teal-600 pl-2 py-2 mt-3"
            style={{ backgroundColor: Colour }}
          >
            BILL TO
          </div>
          <div className="text-[#545454] py-1">{SelectedItems.grn.store.store_name||''}</div>
          <div className="text-[#545454] py-1">
          {SelectedItems.grn.store.icg_warehouse_name||''}
          </div>
          {/* <div className="text-[#545454] py-1">LAGOS</div> */}
        </div>
        <div>
          <div
            className="text-right font-bold text-xl mb-1"
            style={{ color: Colour }}
          >
            INVOICE
          </div>
          <div
            className="border-2 font-normal text-[#545454] flex flex-col"
            style={{ borderColor: Colour }}
          >
            <div className="flex p-2 m-0">
              <span> Date</span>
              <span className="text-right flex-grow ">
                {format(new Date(SelectedItems?.due_date), 'dd-MMM-yyyy')}
              </span>
            </div>
            <div className="flex gap-36 m-0 p-2">
              <span>Reference Number</span>
              <span className="flex-grow text-right">
                {SelectedItems?.external_document_id}
              </span>
            </div>
            <div className="flex gap-36 m-0 p-2">
              <span>Purchase Order Number</span>
              <span className="flex-grow text-right">
                {SelectedItems?.grn?.purchase_order?.po_id}
              </span>
            </div>
            <div className="flex gap-36 m-0 p-2">
              <span>GRN</span>
              <span className="flex-grow text-right">
                {/* ₦
                <NumericFormat
                  value={SelectedItems.gross_total}
                  displayType="text"
                  thousandSeparator={true}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                /> */}
                {SelectedItems.grn.grn_number}
              </span>
            </div>
          </div>
        </div>
      </div>
<div className="bg-white overflow-x-auto md:w-full w-full px-2 mx-auto">
<table className="w-full mt-4 border-gray-200 ">
        <thead
          className=" text-white text-sm w-full"
          style={{ backgroundColor: Colour }}
        >
          <tr>
            <td className="text-sm font-semibold py-2 pl-2">SN</td>
            {/* <td className="text-sm font-semibold py-2 pl-2">GRN</td> */}
            <td className="text-sm font-semibold pl-8">Description</td>
            <td className="text-sm font-semibold pl-4">UoM</td>
            <td className="text-sm font-semibold text-right pr-4 py-2">
              Unit Price
            </td>
            <td className="text-sm font-semibold text-right pr-4">
              Quantity
            </td>
            {taxincluded && <td className="text-sm font-semibold text-right pr-4">
              Tax 
            </td>}
            <td className="text-sm font-semibold text-right pr-4">
              Discount
            </td>
           
            <td className="text-sm font-semibold text-right pr-4">
              Total
            </td>
            {discountincluded && (
              <td className="text-sm font-semibold pl-4">Discount</td>
            )}
          </tr>
        </thead>
        <tbody>
          {SelectedItems.invoice_line_items.map((item, index) => (
            <tr key={index} className="border-b dark:border-gray-700 text-sm">
              <td className="text-sm pl-2 py-3">{index + 1}</td>
              {/* <td className="text-sm pl-2 py-3">{SelectedItems.grn.grn_number}</td> */}
              <td className="pl-8 text-sm">{item?.grn_line_item?.purchase_order_line_item?.product_name}</td>
              <td className="pl-4 text-sm">{item?.grn_line_item?.purchase_order_line_item?.metadata?.ListUnitPriceBaseUnitCode
              }</td>
              <td className="text-sm text-right pr-4">
                ₦<NumericFormat value={item?.grn_line_item?.purchase_order_line_item?.unit_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
              </td>
              <td className="pl-4 text-sm text-right pr-4">
                <NumericFormat value={item?.quantity ? item?.quantity : item?.quantity_received} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
              </td>
              {taxincluded && 
                <td className="text-sm text-right pr-4 pl-8">
                ₦<NumericFormat value={item?.grn_line_item?.tax_value} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                </td>} 
                <td className="text-sm text-right pr-4 pl-8">
                ₦<NumericFormat value={item?.discount_amount?item?.discount_amount:0} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                </td>
              {discountincluded && <td className="text-sm pl-4">{item.discount ? item.discount : 0.00}</td>}
              <td className="text-sm text-right pr-4">
                ₦<NumericFormat value={item?.net_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
</div>
    
<div className="flex w-full px-4 items-center">
<div className="w-full mt-8 text-[#545454]">
<div className="font-bold" style={{ color: Colour }}>
  Note
</div>
<p>
  If you have any questions about this invoice, please contact
  <br />
  vendoremail.com or Call: 08189954748
  <br />
  Thank You For Your Business!
</p>
</div>

<div className="w-full mt-8 text-[#545454]">
<div className="text-right" style={{ color: Colour }}>
  Subtotal: ₦
  <NumericFormat value={SelectedItems.net_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
</div>
<div className="text-right mt-2" style={{ color: Colour }}>
  Tax: ₦
  <NumericFormat value={SelectedItems.total_tax_amount} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
</div>
<div className="text-right mt-2 font-bold text-lg" style={{ color: Colour }}>
  Total: ₦
  <NumericFormat value={SelectedItems.gross_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
</div>
{/* Status section */}
{/* <div className={`text-right mt-2 font-bold text-lg ${statusColor}`}>
  Status: {status}
</div> */}
{/* Comment section */}
{/* {comment && (
  <div className="text-right mt-2" style={{ color: Colour }}>
    <strong>Comment:</strong> {comment}
  </div>
)} */}
</div>
</div>
<div className="py-8">
<div className="flex justify-between items-end px-10 md:px-40 ">
<div>
<p className="text-center text-lg signature"></p>
<p className="text-center text-lg signature">{Signature}</p>
<hr className="divider" />
<p className="text-center text-sm my-2">Vendor Signature</p>
</div>
<div>
<p className="text-center text-sm">
{format(new Date(SelectedItems?.due_date), 'dd-MM-yyyy')}
</p>
<hr className="divider" />
<p className="text-center text-sm my-2">Date</p>
</div>
</div>

<div className="flex flex-col px-10 md:px-40 my-4 space-y-6">
{SelectedItems.workflow.signatures.reverse().map((signatory, index) => (
<div key={index} className="flex justify-between items-end ">
<div>
<p className="text-center text-lg ">
  {SelectedItems.workflow.completed  ? signatory.signer.name : ''}
</p>
<hr className="divider" />
<p className="text-center text-sm my-2">
  { formatText(signatory.role) }
</p>
</div>
<div>
<p className="text-center text-sm">
  {SelectedItems.workflow.completed 
    ? new Date(signatory.date_signed).toLocaleDateString()
    : ''}
</p>
<hr className="divider" />
<p className="text-center text-sm my-2">Date</p>
</div>
</div>
))}
</div>
</div>
    </div>
  
  
  )
}

export default Pdf